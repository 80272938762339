import { type int } from '../type/int'
import { type numeric } from '../type/numeric'

export const IS_UNDEFINED = (v: any): v is undefined => typeof v === 'undefined'

export const IS_DEFINED = <T> (v: T | undefined): v is T => typeof v !== 'undefined'

export const IS_NULL = (v: any): v is null => v === null

export const IS_NOT_NULL = <T> (v: T | null): v is T => v !== null

export const IS_SET = <T> (v: T | null | undefined): v is T => (v !== null) && (typeof v !== 'undefined')

export const IS_NOT_SET = <T> (v: T | null | undefined): v is null | undefined => (v === null) || (typeof v === 'undefined')

export const IS_NUMERIC = (v: any): v is numeric => (
  ((typeof v === 'number') && !Number.isNaN(v)) ||
  ((typeof v === 'string') && (v !== '') && !Number.isNaN(Number(v)) && !Number.isNaN(Number.parseFloat(v)))
)

export const IS_NOT_NUMERIC = (v: any): boolean => !IS_NUMERIC(v)

export const IS_A_NUMBER = (v: any): v is number => (typeof v === 'number') && !isNaN(v)

export const IS_NOT_A_NUMBER = (v: any): boolean => !IS_A_NUMBER(v)

export const IS_A_BIGINT = (v: any): v is bigint => typeof v === 'bigint'

export const IS_NOT_A_BIGINT = <T> (v: T | bigint): v is T => typeof v !== 'bigint'

export const IS_A_BIGINT_OR_NUMERIC = (v: any): v is int => (typeof v === 'bigint') || IS_NUMERIC(v)

export const IS_AN_INTEGER = (v: any): v is int => {
  let bInteger = false

  if (IS_A_BIGINT(v)) {
    bInteger = true
  } else if (IS_A_NUMBER(v)) {
    bInteger = Number.isInteger(v)
  } else if (IS_A_STRING(v)) {
    const x = Number.parseInt(v)
    const y = Number.parseFloat(v)
    const z = Number(v)
    bInteger = (x === y) && (x === z)
  }

  return bInteger
}

export const IS_AN_INT = IS_AN_INTEGER

export const IS_INT = IS_AN_INTEGER

export const IS_A_STRING = (v: any): v is string => typeof v === 'string'

export const IS_A_STRING_AND_EMPTY = (v: any): v is '' => v === ''

export const IS_A_STRING_AND_NOT_EMPTY = (v: any): v is string => (typeof v === 'string') && (v !== '')

export const IS_AN_OBJECT = (o: any): o is any => (o !== null) && (typeof o === 'object')

export const IS_AN_OBJECT_AND_EMPTY = (o: any): o is any => (o !== null) && (typeof o === 'object') && (Object.keys(o).length === 0)

export const IS_AN_OBJECT_AND_NOT_EMPTY = (o: any): o is any => (o !== null) && (typeof o === 'object') && (Object.keys(o).length !== 0)

export const IS_AN_ARRAY = (v: any): v is any[] => Array.isArray(v)

export const IS_AN_ARRAY_AND_EMPTY = (v: any): v is any[] => Array.isArray(v) && (v.length === 0)

export const IS_AN_ARRAY_AND_NOT_EMPTY = (v: any): v is any[] => Array.isArray(v) && (v.length !== 0)

export const IS_A_SET = (o: any): o is Set<any> => o instanceof Set

export const IS_A_SET_AND_NOT_EMPTY = (o: any): o is Set<any> => (o instanceof Set) && (o.size !== 0)

export const IS_A_SET_AND_EMPTY = (o: any): o is Set<any> => (o instanceof Set) && (o.size === 0)

export const IS_A_MAP = (o: any): o is Map<any, any> => o instanceof Map

export const IS_A_MAP_AND_NOT_EMPTY = (o: any): o is Map<any, any> => (o instanceof Map) && (o.size !== 0)

export const IS_A_MAP_AND_EMPTY = (o: any): o is Map<any, any> => (o instanceof Map) && (o.size === 0)

export const IS_A_FUNCTION = (o: any): o is ((_?: any) => any) => typeof o === 'function'

export const IS_A_BOOLEAN = (v: any): v is boolean => typeof v === 'boolean'

export const IS_A_DATE_AND_NOT_EMPTY = (o: any): o is Date => (o instanceof Date) && ((+o - +o) === 0)

export const IS_A_DATE_AND_EMPTY = (o: any): o is Date => (o instanceof Date) && ((+o - +o) !== 0)

export const IS_NOT_EMPTY = <T> (_: T): _ is T => {
  const bNotNull = _ !== null
  const bNotUndefined = typeof _ !== 'undefined'
  const bObject = typeof _ === 'object'
  const bBigint = typeof _ === 'bigint'
  const bNumber = typeof _ === 'number'
  const bString = typeof _ === 'string'
  const bArray = Array.isArray(_)
  const bSet = _ instanceof Set
  const bMap = _ instanceof Map
  const bDate = _ instanceof Date
  return (
    bNotNull && bNotUndefined && // IS_SET(_) &&
    (
      bBigint || // IS_A_BIGINT(_) ||
      (bNumber && !Number.isNaN(_)) || // IS_A_NUMBER(_) ||
      (bString && (_ !== '')) || // IS_A_STRING_AND_NOT_EMPTY(_) ||
      (bArray && (_.length !== 0)) || // IS_AN_ARRAY_AND_NOT_EMPTY(_) ||
      (bSet && (_.size !== 0)) || // IS_A_SET_AND_NOT_EMPTY(_) ||
      (bMap && (_.size !== 0)) || // IS_A_MAP_AND_NOT_EMPTY(_) ||
      (bDate && !Number.isNaN(+_)) || // IS_A_DATE_AND_NOT_EMPTY() ||
      (bObject && !bBigint && !bNumber && !bString && !bArray && !bSet && !bMap && !bDate && (Object.keys(_).length !== 0))
      // IS_AN_OBJECT_AND_NOT_EMPTY(_)
    )
  )
}

export const IS_EMPTY = (v: any): boolean => !IS_NOT_EMPTY(v)

export const IS_OFF = (v: any): boolean => (
  (typeof v === 'undefined') ||
  (v === null) ||
  (v === false) ||
  (v === '') ||
  (v === 0) ||
  (v === 0n) ||
  ((typeof v === 'number') && isNaN(v))
)

export const IS_ON = <T> (v: T): v is T => !IS_OFF(v)

export const IS_EVEN = (v: any): boolean => {
  let res = false

  if (IS_A_BIGINT(v)) {
    res = (v % 2n) === 0n
  } else if (IS_A_NUMBER(v)) {
    res = (v % 2) === 0
  } else if (IS_NUMERIC(v)) {
    res = (Number(v) % 2) === 0
  }

  return res
}

export const IS_ODD = (v: any): boolean => {
  let res = false

  if (IS_A_BIGINT(v)) {
    res = (v % 2n) !== 0n
  } else if (IS_A_NUMBER(v)) {
    res = (v % 2) !== 0
  } else if (IS_NUMERIC(v)) {
    res = (Number(v) % 2) !== 0
  }

  return res
}

export const IS_NUMERIC_AND_SAFE = (v: any): v is numeric => (
  IS_NUMERIC(v) && (Number.EPSILON <= Number(v)) && (Number(v) <= Number.MAX_SAFE_INTEGER)
)

export const IS_NUMERIC_AND_UNSAFE = (v: any): v is numeric => (
  IS_NUMERIC(v) && (Number(v) < Number.EPSILON) && (Number.MAX_SAFE_INTEGER < Number(v))
)

export const REGEXP_NO_WHITESPACE = /^\S*$/g

export const REGEX_EMAIL = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/g

export const REGEXP_NO_WHITESPACE_STRING = '^\\S*$'

export const REGEX_EMAIL_STRING = "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$"

export const IS_A_PUBLIC_URL = (rawUrl?: string): boolean => {
  const url = rawUrl ?? ''
  // allow paths:
  const publicPathWhitelist: string[] = [
    // '/account_token'
  ]
  const bIsAPublicUrl = (
    // here we hardcode that root ( / ) and ( /public followed by _ or / ) paths are public
    ['', '/', '/public_', '/public/'].includes(url.substring(0, 8)) ||
    // double / can happen on loosely configured hostings, remove the "false &&" guard to enable this check
    // but the hosting should be strictly configured in the first place to form canonical urls (with single slashes)
    (false && ['//public_', '//public/'].includes(url.substring(0, 9))) ||
    // (url.substring(0, 15) === '/oauth/v2/token') ||
    // (false && (url.substring(0, 16) === '//oauth/v2/token')) ||
    publicPathWhitelist.some(path => url.split(/[#?]/)[0] === path)
  )
  // console.log({ url, bIsAPublicUrl })
  return bIsAPublicUrl
  // return true
}

export const CHECK = (_: { that: boolean, orThrow?: any }): boolean => {
  if (IS_SET(_.orThrow) && !_.that) {
    throw _.orThrow as Error
  }
  return _.that
}

export const IS_KEY_OF_OBJECT = (_: { key: numeric, of?: any }): boolean => {
  return IS_AN_OBJECT_AND_NOT_EMPTY(_.of) && Object.keys(_.of ?? {}).includes(_.key.toString())
}
