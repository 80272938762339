export class TemplateEnvironment {
  production: '__WEB_CLIENT_PRODUCTION__' | '1' | '0' = '__WEB_CLIENT_PRODUCTION__'
  serverType = '__WEB_CLIENT_SERVER_TYPE__'
  context = '__WEB_CLIENT_CONTEXT__'
  dbSsv = '__WEB_CLIENT_DB_SSV__'
  apiEndpoint = '__WEB_CLIENT_API_ENDPOINT_URL__'
  serverOsFamilly: '__WEB_CLIENT_SERVER_OS_FAMILLY__' | 'linux' | 'windows' | 'macos' = '__WEB_CLIENT_SERVER_OS_FAMILLY__'
  timezone = '__WEB_CLIENT_TIMEZONE__'
  lang = '__WEB_CLIENT_LANG__'
  geo = '__WEB_CLIENT_GEO__'
  locale = '__WEB_CLIENT_LOCALE__'
  csnDisplay = '__WEB_CLIENT_CSN_DISPLAY__'
  synapseInfoUri = '__WEB_CLIENT_SYNAPSE_INFO_URI__'
  synapseClientId = '__WEB_CLIENT_SYNAPSE_CLIENT_ID__'
  hostname = '__WEB_CLIENT_HOSTNAME__'

  constructor (partialDefaultEnvironment: Partial<TemplateEnvironment> = {}) {
    Object.assign(this, partialDefaultEnvironment)
  }
}

export const ENV_TEMPLATE = new TemplateEnvironment()

export const environment = ENV_TEMPLATE
