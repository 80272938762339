import { Component, inject, type OnDestroy, type OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AppDisplay } from 'src/app/type/display'
import { type THEME_TYPE } from 'src/app/type/theme'
import { IS_SET } from 'src/app/util/check.util'
import { DictionaryService } from '../context/dictionary.service'
import { LocaleService } from '../context/locale.service'

@Component({
  selector: 'app-text-preview-dialog',
  templateUrl: './text-preview-dialog.component.html',
  styleUrls: ['./text-preview-dialog.component.scss']
})
export class TextPreviewDialogComponent implements OnInit, OnDestroy {
  MAT_DIALOG_DATA = inject(MAT_DIALOG_DATA)
  MatDialogRef = inject(MatDialogRef<TextPreviewDialogComponent>)
  LocaleService = inject(LocaleService)
  DictionaryService = inject(DictionaryService)

  bAlive = false

  themeType: THEME_TYPE = 'primary'

  title = ''
  message = ''
  text = ''

  display = new AppDisplay({
    cache: {
      close: ''
    }
  })

  async ngOnInit (): Promise<void> {
    this.bAlive = true

    await this.display.updateAll()

    const { themeType, title, message, text, closeText } = this.MAT_DIALOG_DATA

    if (IS_SET(title)) {
      this.title = title
    }

    if (IS_SET(message)) {
      this.message = message
    }

    if (IS_SET(text)) {
      this.text = text
    }

    if (IS_SET(themeType)) {
      this.themeType = themeType
    }

    if (IS_SET(closeText)) {
      this.display.cache.close = closeText
    }
  }

  ngOnDestroy (): void {
    this.bAlive = false
  }
}
