import { TemplateEnvironment } from './environment.template'

export class RsstemplateEnvironment extends TemplateEnvironment {
  // override production
  // override serverType
  override context = 'rss'
  override dbSsv = '3'
  // override apiEndpoint
  // override serverOsFamilly
  override timezone = 'Europe/Paris'
  override lang = 'fr'
  override geo = 'fr'
  override locale = 'fr-fr'
  override csnDisplay = 'hex_card'

  // override synapseInfoUri = 'https://synapse-int.ratp.net/.well-known/openid-configuration'
  override synapseClientId = 'rss-web-client'

  // override synapseInfoUri = 'https://dev-69731473.okta.com/.well-known/openid-configuration'
}

export const ENV_RSSTEMPLATE = new RsstemplateEnvironment()

export const environment = ENV_RSSTEMPLATE
