<div class="content-container m3_card">
  <h1>{{ display.cache.unexpected_error_happened }}</h1>
  <p>{{ display.cache.try_to_connect_again_or_contact_your_system_administrator }}</p>

  <a
    mat-raised-button
    color="primary"
    routerLink="/"
    style="height: 48px;"
  >{{ display.cache.connect_again }}</a>
</div>