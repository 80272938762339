<div
  class="snackbar_content"
  [ngClass]="panelClass"
>
  <div class="snackbar_message">{{ message }}</div>
  <div class="snackbar_close">
    <span
      *ngIf="durationSecD"
      class="duration"
    >{{ durationSecD }}</span>
    <span class="mat_icon_button_density_0_container">
      <button
        *ngIf="!closeString"
        (click)="close()"
        mat-icon-button
        [ngClass]="closeClass"
      >
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </span>
    <span class="mat_button_density_0_container">
      <button
        *ngIf="closeString"
        (click)="close()"
        mat-button
        [ngClass]="closeClass"
      >{{ closeString }}</button>
    </span>
  </div>
</div>
