import { environment } from '../../environments/environment'
import { APP_UUID } from '../app-uuid'
import { IS_A_STRING, IS_ON, IS_SET } from './check.util'
import { TO_NUMBER, TO_STRING } from './convert.util'
import { LOCAL_STORAGE_GET_VALUE_AT_KEY, LOCAL_STORAGE_REMOVE_AT_KEY, LOCAL_STORAGE_SET_OBJECT_AT_KEY } from './local-storage.util'
import { NOW_APP } from './time.util'

export const ENV_OVERRIDE_LOCAL_STORAGE_KEY = `_app_${APP_UUID}_env_override_cache`

export const GET_ENV_OVERRIDE = async (override?: Record<string, any>): Promise<Record<string, any> | undefined> => {
  let _override: Record<string, any> | undefined = override

  if (!IS_SET(_override)) {
    const localOverride = TO_STRING(LOCAL_STORAGE_GET_VALUE_AT_KEY(ENV_OVERRIDE_LOCAL_STORAGE_KEY))

    try { _override = JSON.parse(localOverride) } catch { }
  }

  if (!IS_SET(_override)) {
    const { origin, pathname } = window.location
    const url = `${origin}${pathname}/../assets/env.override.json`

    try { _override = await fetch(url).then(async r => (await r.json())) } catch { }

    if (IS_SET(_override) && !IS_A_STRING(_override)) {
      const expires = (new Date(+NOW_APP().plus({ seconds: 5 }))).toUTCString()
      LOCAL_STORAGE_SET_OBJECT_AT_KEY({ key: ENV_OVERRIDE_LOCAL_STORAGE_KEY, value: _override, attributes: { expires } })
    } else {
      LOCAL_STORAGE_REMOVE_AT_KEY(ENV_OVERRIDE_LOCAL_STORAGE_KEY)
    }
  }

  return _override
}

export interface ENV_PROD_DEV_OVERRIDE_PARAMS {
  bProd?: boolean
  bDev?: boolean
  override?: Record<string, any>
}

export const IS_ENV_PROD = async (_?: ENV_PROD_DEV_OVERRIDE_PARAMS): Promise<boolean> => {
  const _bProd = _?.bProd
  const _bDev = _?.bDev

  const bProdFromParams: boolean | undefined = _bProd ?? (IS_SET(_bDev) ? !_bDev : undefined)

  return bProdFromParams ?? IS_ON((await GET_ENV_OVERRIDE(_?.override))?.WEB_CLIENT_PRODUCTION ?? environment.production)
}

export const IS_ENV_DEV = async (_?: ENV_PROD_DEV_OVERRIDE_PARAMS): Promise<boolean> => !(await IS_ENV_PROD(_))

export const GET_ENV_ENV = async (_?: ENV_PROD_DEV_OVERRIDE_PARAMS): Promise<'production' | 'development'> => {
  const _bDev = _?.bDev
  const bProduction = _?.bProd ?? (IS_SET(_bDev) ? !_bDev : undefined) ?? (await IS_ENV_PROD(_?.override))
  return bProduction ? 'production' : 'development'
}

export interface ENV_SERVER_TYPE_OVERRIDE_PARAMS {
  serverType?: string
  override?: Record<string, any>
}

export const GET_ENV_SERVER_TYPE = async (_?: ENV_SERVER_TYPE_OVERRIDE_PARAMS): Promise<string> => {
  return TO_STRING(_?.serverType ?? (await GET_ENV_OVERRIDE(_?.override))?.WEB_CLIENT_SERVER_TYPE ?? environment.serverType)
}

export const IS_ENV_SERVER_TYPE_DEV = async (_?: ENV_SERVER_TYPE_OVERRIDE_PARAMS): Promise<boolean> => {
  return (await GET_ENV_SERVER_TYPE(_)) === 'DEV'
}
export const IS_ENV_SERVER_TYPE_DEVLOCAL = async (_?: ENV_SERVER_TYPE_OVERRIDE_PARAMS): Promise<boolean> => {
  return (await GET_ENV_SERVER_TYPE(_)) === 'DEVLOCAL'
}
export const IS_ENV_SERVER_TYPE_DEVTESTREMOTE = async (_?: ENV_SERVER_TYPE_OVERRIDE_PARAMS): Promise<boolean> => {
  return (await GET_ENV_SERVER_TYPE(_)) === 'DEVTESTREMOTE'
}
export const IS_ENV_SERVER_TYPE_TEST = async (_?: ENV_SERVER_TYPE_OVERRIDE_PARAMS): Promise<boolean> => {
  return (await GET_ENV_SERVER_TYPE(_)) === 'TEST'
}
export const IS_ENV_SERVER_TYPE_DEMO = async (_?: ENV_SERVER_TYPE_OVERRIDE_PARAMS): Promise<boolean> => {
  return (await GET_ENV_SERVER_TYPE(_)) === 'DEMO'
}
export const IS_ENV_SERVER_TYPE_PREPROD = async (_?: ENV_SERVER_TYPE_OVERRIDE_PARAMS): Promise<boolean> => {
  return (await GET_ENV_SERVER_TYPE(_)) === 'PREPROD'
}
export const IS_ENV_SERVER_TYPE_PROD = async (_?: ENV_SERVER_TYPE_OVERRIDE_PARAMS): Promise<boolean> => {
  return (await GET_ENV_SERVER_TYPE(_)) === 'PROD'
}

export interface ENV_CONTEXT_OVERRIDE_PARAMS {
  context?: string
  override?: Record<string, any>
}

export const GET_ENV_APP_CONTEXT = async (_?: ENV_CONTEXT_OVERRIDE_PARAMS): Promise<string> => {
  return TO_STRING(_?.context ?? (await GET_ENV_OVERRIDE(_?.override))?.WEB_CLIENT_CONTEXT ?? environment.context).toLowerCase()
}

export const IS_ENV_ACTV = async (_?: ENV_CONTEXT_OVERRIDE_PARAMS): Promise<boolean> => {
  return (await GET_ENV_APP_CONTEXT(_)) === 'actv'
}
export const IS_ENV_CDMX = async (_?: ENV_CONTEXT_OVERRIDE_PARAMS): Promise<boolean> => {
  return (await GET_ENV_APP_CONTEXT(_)) === 'cdmx'
}
export const IS_ENV_ER = async (_?: ENV_CONTEXT_OVERRIDE_PARAMS): Promise<boolean> => {
  return (await GET_ENV_APP_CONTEXT(_)) === 'er'
}
export const IS_ENV_MONTREAL = async (_?: ENV_CONTEXT_OVERRIDE_PARAMS): Promise<boolean> => {
  return (await GET_ENV_APP_CONTEXT(_)) === 'montreal'
}
export const IS_ENV_PARIS = async (_?: ENV_CONTEXT_OVERRIDE_PARAMS): Promise<boolean> => {
  return (await GET_ENV_APP_CONTEXT(_)) === 'paris'
}
export const IS_ENV_PUEBLA = async (_?: ENV_CONTEXT_OVERRIDE_PARAMS): Promise<boolean> => {
  return (await GET_ENV_APP_CONTEXT(_)) === 'puebla'
}
export const IS_ENV_RSS = async (_?: ENV_CONTEXT_OVERRIDE_PARAMS): Promise<boolean> => {
  return (await GET_ENV_APP_CONTEXT(_)) === 'rss'
}
export const IS_ENV_SNCB = async (_?: ENV_CONTEXT_OVERRIDE_PARAMS): Promise<boolean> => {
  return (await GET_ENV_APP_CONTEXT(_)) === 'sncb'
}
export const IS_ENV_SPIRTECH = async (_?: ENV_CONTEXT_OVERRIDE_PARAMS): Promise<boolean> => {
  return (await GET_ENV_APP_CONTEXT(_)) === 'spirtech'
}

export interface ENV_CSN_DISPLAY_OVERRIDE_PARAMS {
  csnDisplay?: string
  override?: Record<string, any>
}

export const GET_ENV_CSN_DISPLAY = async (_?: ENV_CSN_DISPLAY_OVERRIDE_PARAMS): Promise<string> => {
  return TO_STRING(_?.csnDisplay ?? (await GET_ENV_OVERRIDE(_?.override))?.WEB_CLIENT_CSN_DISPLAY ?? environment.csnDisplay)
}

export const IS_ENV_CSN_DEC_NUMBER = async (_?: ENV_CSN_DISPLAY_OVERRIDE_PARAMS): Promise<boolean> => {
  return (await GET_ENV_CSN_DISPLAY(_)) === 'dec_number'
}
export const IS_ENV_CSN_HEX_NUMBER = async (_?: ENV_CSN_DISPLAY_OVERRIDE_PARAMS): Promise<boolean> => {
  return (await GET_ENV_CSN_DISPLAY(_)) === 'hex_number'
}
export const IS_ENV_CSN_HEX_CARD = async (_?: ENV_CSN_DISPLAY_OVERRIDE_PARAMS): Promise<boolean> => {
  return (await GET_ENV_CSN_DISPLAY(_)) === 'hex_card'
}

export interface ENV_DB_SSV_OVERRIDE_PARAMS {
  dbSsv?: number
  override?: Record<string, any>
}

export const GET_ENV_DB_SSV = async (_?: ENV_DB_SSV_OVERRIDE_PARAMS): Promise<number> => {
  return TO_NUMBER(_?.dbSsv ?? (await GET_ENV_OVERRIDE(_?.override))?.WEB_CLIENT_DB_SSV ?? environment.dbSsv)
}

export const GET_ENV_DB_SSV_BEFORE_V1 = async (_?: ENV_DB_SSV_OVERRIDE_PARAMS): Promise<boolean> => (await GET_ENV_DB_SSV(_)) < 1
export const GET_ENV_DB_SSV_BEFORE_V2 = async (_?: ENV_DB_SSV_OVERRIDE_PARAMS): Promise<boolean> => (await GET_ENV_DB_SSV(_)) < 2
export const GET_ENV_DB_SSV_BEFORE_V3 = async (_?: ENV_DB_SSV_OVERRIDE_PARAMS): Promise<boolean> => (await GET_ENV_DB_SSV(_)) < 3
export const GET_ENV_DB_SSV_FROM_V3 = async (_?: ENV_DB_SSV_OVERRIDE_PARAMS): Promise<boolean> => (await GET_ENV_DB_SSV(_)) >= 3

export const GET_ENV_LOCALE = async (_?: { locale?: string, override?: Record<string, any> }): Promise<string> => {
  return TO_STRING(_?.locale ?? (await GET_ENV_OVERRIDE(_?.override))?.WEB_CLIENT_LOCALE ?? environment.locale)
}

export const GET_ENV_TIMEZONE = async (_?: { timezone?: string, override?: Record<string, any> }): Promise<string> => {
  return TO_STRING(_?.timezone ?? (await GET_ENV_OVERRIDE(_?.override))?.WEB_CLIENT_TIMEZONE ?? environment.timezone)
}

export const GET_ENV_API_ENDPOINT = async (_?: { apiEndpoint: string, override?: Record<string, any> }): Promise<string> => {
  return TO_STRING(_?.apiEndpoint ?? (await GET_ENV_OVERRIDE(_?.override))?.WEB_CLIENT_API_ENDPOINT_URL ?? environment.apiEndpoint)
}

export const GET_ENV_SYNAPSE_INFO_URI = async (_?: { synapseInfoUri: string, override?: Record<string, any> }): Promise<string> => {
  return TO_STRING(_?.synapseInfoUri ?? (await GET_ENV_OVERRIDE(_?.override))?.WEB_CLIENT_SYNAPSE_INFO_URI ?? environment.synapseInfoUri)
}

export const GET_ENV_SYNAPSE_CLIENT_ID = async (_?: { synapseClientId: string, override?: Record<string, any> }): Promise<string> => {
  return TO_STRING(_?.synapseClientId ?? (await GET_ENV_OVERRIDE(_?.override))?.WEB_CLIENT_SYNAPSE_CLIENT_ID ?? environment.synapseClientId)
}

export const GET_ENV_HOSTNAME = async (_?: { hostname: string, override?: Record<string, any> }): Promise<string> => {
  return TO_STRING(_?.hostname ?? (await GET_ENV_OVERRIDE(_?.override))?.WEB_CLIENT_HOSTNAME ?? environment.hostname)
}
