<div class="content-container m3_card">
  <img src="assets/404-cat.svg" style="max-width: 400px;">
  <h1 style="max-width: 800px; margin: 2rem; text-align: center;">
    {{ display.cache.this_account_is_not_authorized_to_access_this_application_ }}
    {{ display.cache.contact_your_system_administrator_or_try_with_another_account_ }}
  </h1>

  <a
    mat-raised-button
    color="primary"
    routerLink="/"
    style="height: 48px;"
  >{{ display.cache.try_with_another_account }}</a>
</div>
