import { IS_A_STRING_AND_NOT_EMPTY, IS_SET } from './check.util'
import { TO_STRING } from './convert.util'
import { NOW_MS } from './time.util'

export const LOCAL_STORAGE_IS_OPEN = (): boolean => IS_SET(localStorage)

export const LOCAL_STORAGE_GET_OBJECT_AT_KEY_AT_ALL_COSTS = (key: string): string => {
  let res: any

  if (LOCAL_STORAGE_IS_OPEN() && IS_A_STRING_AND_NOT_EMPTY(key)) {
    let itemFound: any
    try { itemFound = JSON.parse(TO_STRING(localStorage.getItem(TO_STRING(key)))) } catch { }

    if (IS_SET(itemFound?.value)) {
      res = itemFound
    }
  }

  return res
}

export const LOCAL_STORAGE_GET_EXPIRATION_MS_OF_OBJECT = (item: any): number => {
  let expiresMs = NaN

  if (LOCAL_STORAGE_IS_OPEN() && IS_SET(item?.attributes?.expires)) {
    const expiresUtcString = TO_STRING(item?.attributes?.expires)

    if (IS_A_STRING_AND_NOT_EMPTY(expiresUtcString)) {
      expiresMs = +(new Date(expiresUtcString))
    }
  }

  return expiresMs
}

export const LOCAL_STORAGE_GET_EXPIRATION_MS_AT_KEY = (key: string): number => {
  let res = NaN

  if (LOCAL_STORAGE_IS_OPEN()) {
    const itemFound = LOCAL_STORAGE_GET_OBJECT_AT_KEY_AT_ALL_COSTS(key)
    res = LOCAL_STORAGE_GET_EXPIRATION_MS_OF_OBJECT(itemFound)
  }

  return res
}

export const LOCAL_STORAGE_IS_EXPIRED_AT_KEY = (key: string): boolean => {
  return LOCAL_STORAGE_IS_OPEN() ? (LOCAL_STORAGE_GET_EXPIRATION_MS_AT_KEY(key) < NOW_MS()) : true
}

export const LOCAL_STORAGE_IS_EXPIRED_ITEM = (item: any): boolean => {
  return LOCAL_STORAGE_IS_OPEN() ? (LOCAL_STORAGE_GET_EXPIRATION_MS_OF_OBJECT(item) < NOW_MS()) : true
}

export const LOCAL_STORAGE_GET_ITEM_AT_KEY_IF_NOT_EXPIRED = (key: string): any => {
  let res: any

  if (LOCAL_STORAGE_IS_OPEN() && IS_A_STRING_AND_NOT_EMPTY(key)) {
    const itemFound = LOCAL_STORAGE_GET_OBJECT_AT_KEY_AT_ALL_COSTS(key)

    if (IS_SET(itemFound)) {
      const bExpired = LOCAL_STORAGE_IS_EXPIRED_ITEM(itemFound)

      if (!bExpired) {
        res = itemFound
      }
    }
  }

  return res
}

export const LOCAL_STORAGE_GET_EXPIRES_AT_KEY = (key: string): string => {
  let res = ''

  if (LOCAL_STORAGE_IS_OPEN()) {
    const itemFound = LOCAL_STORAGE_GET_ITEM_AT_KEY_IF_NOT_EXPIRED(key)

    if (IS_SET(itemFound)) {
      res = TO_STRING(itemFound?.attributes?.expires)
    }
  }

  return res
}

export const LOCAL_STORAGE_REMOVE_AT_KEY = (key: string): void => {
  if (LOCAL_STORAGE_IS_OPEN()) {
    localStorage.removeItem(key)
  }
}

/**
 * @returns the value stored in localStorage
 * - string -> identified by the given _.key
 * - Record of all the key-values stored -> if no _.key is given
 * - undefined -> expired OR not_found
 */
export const LOCAL_STORAGE_GET_VALUE_AT_KEY = (key?: string): string | Record<string, any> | undefined => {
  let res: string | Record<string, any> | undefined

  if (LOCAL_STORAGE_IS_OPEN()) {
    if (IS_A_STRING_AND_NOT_EMPTY(key)) {
      const found = LOCAL_STORAGE_GET_ITEM_AT_KEY_IF_NOT_EXPIRED(key)

      if (IS_SET(found)) {
        res = found?.value
      } else {
        LOCAL_STORAGE_REMOVE_AT_KEY(key)
      }
    } else {
      const keyValue: Record<string, any> = {}

      const localStorageLength = localStorage.length

      for (let i = 0; i < localStorageLength; i += 1) {
        const key = TO_STRING(localStorage.key(i))
        const value = LOCAL_STORAGE_GET_VALUE_AT_KEY(key)

        keyValue[key] = value
      }

      res = keyValue
    }
  }

  return res
}

export const LOCAL_STORAGE_SET_OBJECT_AT_KEY = (_: { key: string, value: any, attributes?: any }): void => {
  if (LOCAL_STORAGE_IS_OPEN()) {
    localStorage.setItem(_.key, JSON.stringify({ value: _.value, attributes: _.attributes }))
  }
}
