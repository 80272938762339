import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { lastValueFrom, Observable, of } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { type Q } from 'src/app/type/q'
import { TO_NUMBER, TO_STRING } from 'src/app/util/convert.util'
import { GET_ENV_API_ENDPOINT, IS_ENV_RSS } from 'src/app/util/env.util'
import { NOW_APP } from 'src/app/util/time.util'
import { _resultOf, type ExceptionResult } from '../../util/async.util'
import { IS_A_STRING_AND_NOT_EMPTY, IS_SET } from '../../util/check.util'
import { AuthService } from '../auth/auth.service'
import { IS_SYNAPSE_ERROR } from '../okta/okta.utils'
import { Router } from '@angular/router'

export const GET_EXCEPTION_RESULT_OF = async (p: Promise<any>, errorExt?: any): Promise<ExceptionResult> => {
  const [exception, result] = await p
    .then(data => [undefined, data])
    .catch(err => {
      if (IS_SET(errorExt)) {
        Object.assign(err, errorExt)
      }

      return [err, undefined]
    })

  return { exception, result }
}

export interface UrlParams { url?: string, api?: string, path?: string }

export interface GetParams extends UrlParams { options?: any, q?: Partial<Q> }
export interface PostParams extends GetParams { body?: any }
export interface PutParams extends PostParams { }
export interface DeleteParams extends GetParams { }
export interface GetCsvParams extends GetParams { pageName?: string }
export interface GetPdfParams extends GetParams { pageName?: string }

@Injectable({ providedIn: 'root' })
export class HttpService {
  HttpClient = inject(HttpClient)
  AuthService = inject(AuthService)
  Router = inject(Router)

  typeToExt: Record<string, string> = {
    'application/csv': '.csv',
    'application/pdf': '.pdf'
  }

  async tryRefreshCurrentUser(): Promise<boolean> {
    const userData = await this.AuthService.refreshCurrentUser()

    return IS_SET(userData)
  }

  async onRequestException <T extends ExceptionResult> (_: {
    res: T,
    method: 'get' | 'post' | 'put' | 'delete',
    params: GetParams | PostParams | PutParams | DeleteParams
  }): Promise<T> {
    let { res, method, params } = _

    const exception = res.exception

    const status = TO_NUMBER(exception.status)

    const bSynapseError = IS_A_STRING_AND_NOT_EMPTY(exception.error.message) && IS_SYNAPSE_ERROR(exception.error.message)

    const bUserRefreshed = bSynapseError ? await this.tryRefreshCurrentUser() : false

    if (bUserRefreshed) {
      const options = this.AuthService.getHttpOptionsWithBearerTokenAndContentTypeJson()

      options.params = params.options?.params

      // ? retry the request using the same method (getting the new jwt)
      res = await this[method](params) as T
    } else if (bSynapseError || status === 401 || status === 403) {
      if (await IS_ENV_RSS()) {
        this.Router.navigate(['/synapse-error'])
      } else {
        this.Router.navigate(['/login'])
      }
    }

    return res
  }

  async getRequestExceptionOrResult (_: {
    request$: Observable<any>
    method: 'get' | 'post' | 'put' | 'delete'
    params: GetParams | PostParams | PutParams | DeleteParams
  }): Promise<ExceptionResult> {
    let res: ExceptionResult = { exception: undefined, result: undefined }

    try {
      res = await GET_EXCEPTION_RESULT_OF(lastValueFrom(_.request$))
    } catch (err) {
      res.exception = err
    }

    const err = res.exception

    if (IS_SET(err)) {
      res = await this.onRequestException({ ..._, res })
    }

    return res
  }

  async generateUrl (_: UrlParams): Promise<string> {
    return _.url ?? `${_.api ?? (await GET_ENV_API_ENDPOINT())}${TO_STRING(_.path)}`
  }

  async structure (request: Observable<any> | Promise<any>): Promise<ExceptionResult> {
    const res = await _resultOf((request instanceof Observable) ? lastValueFrom(request) : request)
    return res
  }

  async throwIfError (request: Promise<ExceptionResult>): Promise<ExceptionResult> {
    const response = await request
    if (IS_SET(response.exception)) {
      throw response.exception
    }
    return response
  }

  async get (_: GetParams): Promise<ExceptionResult> {
    const options: any = _.options ?? {}

    const bOptionsParams = IS_SET(options.params)

    const qJsonString = JSON.stringify(_.q)

    if (IS_A_STRING_AND_NOT_EMPTY(qJsonString)) {
      if (bOptionsParams) {
        options.params.q = qJsonString
      } else {
        options.params = { q: qJsonString }
      }
    }

    const request$ = this.HttpClient.get(await this.generateUrl(_), options)

    const res = await this.getRequestExceptionOrResult({ request$, method: 'get', params: _ })

    return res

    // const res = await this.structure(this.HttpClient.get(await this.generateUrl(_), options))

    // return res
  }

  async post (_: PostParams): Promise<ExceptionResult> {
    // const res = await this.structure(this.HttpClient.post(await this.generateUrl(_), _.body, _.options))
    // return res

    const request$ = this.HttpClient.post(await this.generateUrl(_), _.body, _.options)

    const res = await this.getRequestExceptionOrResult({ request$, method: 'post', params: _ })

    return res
  }

  async put (_: PutParams): Promise<ExceptionResult> {
    // const res = await this.structure(this.HttpClient.put(await this.generateUrl(_), _.body, _.options))
    // return res

    const request$ = this.HttpClient.put(await this.generateUrl(_), _.body, _.options)

    const res = await this.getRequestExceptionOrResult({ request$, method: 'put', params: _ })

    return res
  }

  async delete (_: DeleteParams): Promise<ExceptionResult> {
    // const res = await this.structure(this.HttpClient.delete(await this.generateUrl(_), _.options))
    // return res

    const request$ = this.HttpClient.delete(await this.generateUrl(_), _.options)

    const res = await this.getRequestExceptionOrResult({ request$, method: 'delete', params: _ })

    return res
  }

  fileDownLoad (_: { data: any, type: string, pageName?: string }): void {
    const { data, type } = _
    const blob = new Blob([data], { type })
    const blobUrl = window.URL.createObjectURL(blob)
    const domId = 'dom_a_file_download'
    const domAFound = document.getElementById(domId)
    const domA: HTMLElement = domAFound ?? document.createElement('a')

    if (!IS_SET(domAFound)) {
      domA.id = domId
      domA.setAttribute('style', 'display:none')
      document.body.appendChild(domA)
    }

    domA.setAttribute('href', blobUrl)

    const pageNameDisplay = IS_A_STRING_AND_NOT_EMPTY(_.pageName) ? `-${TO_STRING(_.pageName)}` : ''
    domA.setAttribute('download', `${NOW_APP().toFormat('yMMdd_HHmmss')}${pageNameDisplay}-export${TO_STRING(this.typeToExt[type])}`)

    domA.click()

    document.body.removeChild(domA)
    window.URL.revokeObjectURL(blobUrl)
  }

  async getCsv (_: GetCsvParams): Promise<any> {
    this
      .HttpClient
      .get(await this.generateUrl(_), { responseType: 'arraybuffer', ...(_.options ?? {}) })
      .pipe(
        tap(res => { this.fileDownLoad({ data: res, type: 'application/csv', pageName: _.pageName }) }),
        catchError(err => {
          console.error(err)
          return of(err)
        })
      )
      .subscribe()
  }

  async getPdf (_: GetPdfParams): Promise<any> {
    this
      .HttpClient
      .get(await this.generateUrl(_), { responseType: 'arraybuffer', ...(_.options ?? {}) })
      .pipe(
        tap(res => { this.fileDownLoad({ data: res, type: 'application/pdf', pageName: _.pageName }) }),
        catchError(err => {
          console.error(err)
          return of(err)
        })
      )
      .subscribe()
  }
}
