import { Component, OnInit } from "@angular/core"
import { MatButtonModule } from "@angular/material/button"
import { RouterModule } from "@angular/router"
import { AppDisplay } from "src/app/type/display"

@Component({
  selector: 'app-logged-out',
  templateUrl: './logged-out.component.html',
  styleUrls: ['./logged-out.component.scss'],
  imports: [MatButtonModule, RouterModule],
  standalone: true,
})
export class AppLoggedOutPage implements OnInit {
  display = new AppDisplay({
    cache: {
      unexpected_error_happened: '',
      you_have_been_successfully_logged_out_from_synapse: '',
      connect_again: '',
    },
    entries: {
      unexpected_error_happened: {
        default: 'Unexpected error happened',
        fr: 'Une erreur inattendue s\'est produite',
        es: 'Se produjo un error inesperado',
        it: 'Si è verificato un errore imprevisto'
      },
      you_have_been_successfully_logged_out_from_synapse: {
        default: 'You have been successfully logged out from Synapse',
        fr: 'Vous avez été déconnecté de Synapse avec succès',
        es: 'Se ha desconectado correctamente de Synapse',
        it: 'Sei stato disconnesso con successo da Synapse'
      },
      connect_again: {
        default: 'Connect again',
        fr: 'Se connecter à nouveau',
        es: 'Volver a conectarse',
        it: 'Riconnettersi'
      }
    }
  })

  ngOnInit() {
    this.display.updateAll()
    Object.keys(localStorage).forEach((key) => {
      if (key.includes('okta')) {
        localStorage.removeItem(key)
      }
    })
  }
}