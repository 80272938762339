import { Component, inject, type OnDestroy, type OnInit } from '@angular/core'
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar'
import { interval } from 'rxjs'
import { switchMap, takeWhile } from 'rxjs/operators'
import { IS_NUMERIC_AND_SAFE } from '../../util/check.util'
import { TO_NUMBER } from '../../util/convert.util'
import { LocaleService } from '../context/locale.service'

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit, OnDestroy {
  data = inject(MAT_SNACK_BAR_DATA)
  MatSnackBarRef = inject<MatSnackBarRef<SnackbarComponent>>(MatSnackBarRef<SnackbarComponent>)
  LocaleService = inject(LocaleService)

  bAlive = false

  panelClass = []
  message = ''
  closeString = ''
  closeClass = []

  durationSec = NaN
  durationSecD = ''

  async ngOnInit (): Promise<void> {
    this.bAlive = true

    const { panelClass, message, closeString, closeClass, duration } = this.data
    this.panelClass = panelClass
    this.message = message
    this.closeString = closeString
    this.closeClass = closeClass

    const durationSec = TO_NUMBER(duration) / 1000
    this.durationSec = (IS_NUMERIC_AND_SAFE(durationSec) && (durationSec > 3)) ? durationSec : NaN
    this.durationSecD = await this.LocaleService.localizeNumber({ value: this.durationSec })

    if (!Number.isNaN(this.durationSec)) {
      interval(1000).pipe(
        takeWhile(() => this.bAlive && (this.durationSec > 1)),
        switchMap(async () => {
          this.durationSec -= 1
          this.durationSecD = (this.durationSec < 2) ? '' : (await this.LocaleService.localizeNumber({ value: this.durationSec }))
        })
      ).subscribe()
    }
  }

  close (): void {
    this.MatSnackBarRef.dismiss()
  }

  ngOnDestroy (): void {
    this.bAlive = false
  }
}
