<div class="content-container m3_card">
  <h1>{{ display.cache.you_have_been_successfully_logged_out_from_synapse }}</h1>

  <a
    mat-raised-button
    color="primary"
    routerLink="/"
    style="height: 48px;"
  >{{ display.cache.connect_again }}</a>
</div>
