import { Settings } from 'luxon'
import { environment } from '../../environments/environment'
import { APP_UUID } from '../app-uuid'
import { TO_STRING } from './convert.util'
import { GET_ENV_LOCALE } from './env.util'

export const LOCALE_STORAGE_KEY = `_app_${APP_UUID}_locale`

export const GET_INITIAL_LOCALE = async (): Promise<string> => {
  return TO_STRING(localStorage.getItem(LOCALE_STORAGE_KEY) ?? (await GET_ENV_LOCALE()) ?? environment.locale)
}
export const SET_LOCALE = (locale: string): void => {
  Settings.defaultLocale = locale
  document.documentElement.setAttribute('lang', locale)
  localStorage.setItem(LOCALE_STORAGE_KEY, locale)
}
export const GET_LOCALE = (): string => Settings.defaultLocale
