import { DateTime } from 'luxon'
import { type int } from '../type/int'
import { type numeric } from '../type/numeric'
import { IS_AN_ARRAY, IS_AN_ARRAY_AND_NOT_EMPTY, IS_AN_OBJECT, IS_AN_OBJECT_AND_NOT_EMPTY, IS_A_BIGINT, IS_A_BOOLEAN, IS_A_DATE_AND_NOT_EMPTY, IS_A_FUNCTION, IS_A_MAP, IS_A_MAP_AND_NOT_EMPTY, IS_A_NUMBER, IS_A_SET, IS_A_SET_AND_NOT_EMPTY, IS_A_STRING, IS_A_STRING_AND_NOT_EMPTY, IS_EMPTY, IS_NOT_EMPTY, IS_NOT_SET, IS_NUMERIC, IS_OFF, IS_ON, IS_SET } from './check.util'
import { SYMBOLS, SYMBOLS_BASE_256, SYMBOLS_LENGTH } from './constant.util'

export const TO_STRING = (_: any): string => {
  let res = ''

  if (IS_A_STRING(_)) {
    res = _
  } else if (IS_SET(_) && IS_A_FUNCTION(_.toString)) {
    res = _.toString()
  } else if (IS_NOT_EMPTY(_)) {
    res = JSON.stringify(_)
  }

  return res
}

export const TO_CANONICAL_STRING = (_: any): string => {
  return TO_STRING(_).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
}

export const TO_NUMBER = (v: any): number => {
  let res = NaN

  if (IS_A_NUMBER(v)) {
    res = v
  } else if (IS_A_BOOLEAN(v)) {
    res = v ? 1 : 0
  } else if (IS_A_BIGINT(v) || IS_NUMERIC(v)) {
    res = Number(v)
  }

  return res
}

export const TO_BIGINT = <T = null> (n: any): bigint | T => {
  let res: any = null

  if (IS_A_BIGINT(n)) {
    res = n
  } else {
    try { res = BigInt(n) } catch {
      try { BigInt(TO_STRING(n).split('.')[0]) } catch { }
    }
  }

  return res
}

export const TO_BOOLEAN = (v: any): boolean => IS_NUMERIC(v) ? IS_ON(TO_NUMBER(v)) : !IS_EMPTY(v)

/**
 * base convert big base small numbers
 */
export const BASE_CONVERT_BBSN = (n: int, from: int, to: int): string | null => {
  const N = `${n}`

  const f = TO_NUMBER(from)

  if ((f <= 0) || (SYMBOLS_LENGTH < f)) {
    console.log(`Base from ${from} not in 1..${SYMBOLS_LENGTH}`)

    return null
  }

  const t = TO_NUMBER(to)

  if ((t <= 0) || (SYMBOLS_LENGTH < t)) {
    console.log(`Base to ${to} not in 1..${SYMBOLS_LENGTH}`)

    return null
  }

  let nBaseTen = 0
  if (f === 10) {
    nBaseTen = parseInt(N)
  } else {
    const sizeN = N.length

    for (let i = 0; i < sizeN; i += 1) {
      const Ni = N[i]

      let mul = 0
      let bMulOk = false

      while (mul < SYMBOLS_LENGTH) {
        bMulOk = Ni === SYMBOLS[mul]

        if (bMulOk) {
          break
        } else {
          mul += 1
        }
      }

      if (f <= mul) {
        console.log(`Symbol not allowed in base ${from}`)

        return null
      }

      if (bMulOk) {
        console.log('Symbol not found')

        return null
      }

      const exp = sizeN - i - 1
      nBaseTen += mul * ((exp === 0) ? 1 : Math.pow(f, exp))
    }
  }

  if (t !== 10) {
    const nTo: any[] = (nBaseTen === 0) ? [SYMBOLS[0]] : []

    while (nBaseTen > 0) {
      const mod = nBaseTen % t

      if ((mod < 0) || (SYMBOLS_LENGTH <= mod)) {
        console.log(`Out of bounds mod ${mod} not in 0..${SYMBOLS_LENGTH}`)

        return null
      }

      nTo.push(SYMBOLS[mod])

      nBaseTen = Number.parseInt(`${nBaseTen / t}`)
    }

    return nTo.reverse().toString().replace(/,/g, '')
  }

  return nBaseTen.toString()
}

/**
 * from pure numeric string in base [2,36] to base [2,36] else throws
 */
export const REBASE = (_: { from: numeric, base: numeric, toBase: numeric }): string => {
  const { from, base, toBase } = _

  const range = SYMBOLS.split('')
  const fromRange = range.slice(0, TO_NUMBER(base))
  const toRange = range.slice(0, TO_NUMBER(toBase))

  let decValue = `${from}`.split('').reverse().reduce(
    (carry, digit, index) => {
      const indexOfDigit = fromRange.indexOf(digit)

      if (indexOfDigit === -1) {
        throw new Error(`Invalid digit '${digit}' in '${from}' for base ${base}`)
      }

      carry += indexOfDigit * (Math.pow(TO_NUMBER(base), index))

      return carry
    },
    0
  )

  let newValue = ''

  while (decValue > 0) {
    newValue = toRange[decValue % TO_NUMBER(toBase)] + newValue
    decValue = (decValue - (decValue % TO_NUMBER(toBase))) / TO_NUMBER(toBase)
  }

  return (newValue === '') ? '0' : newValue
}

export const BIG_ADD_10 = (_x: numeric, _y: numeric): string => {
  let c = 0

  const r: number[] = []
  const x = `${_x}`.split('').map(Number)
  const y = `${_y}`.split('').map(Number)

  while ((x.length !== 0) || (y.length !== 0)) {
    const s = (x.pop() ?? 0) + (y.pop() ?? 0) + c
    r.unshift(s < 10 ? s : s - 10)
    c = s < 10 ? 0 : 1
  }

  if (c !== 0) {
    r.unshift(c)
  }

  return r.join('')
}

export const BIG_16_TO_BIG_10 = (_s: numeric): string => {
  let dec = '0'

  const input = (IS_SET(_s) && (((typeof _s === 'number') && !isNaN(_s)) || (_s !== 'NaN'))) ? `${_s}` : ''

  input.split('').forEach(c => {
    const n = parseInt(c, 16)

    for (let t = 8; t !== 0; t >>= 1) {
      dec = BIG_ADD_10(dec, dec)

      if ((n & t) !== 0) {
        dec = BIG_ADD_10(dec, '1')
      }
    }
  })

  return (input !== '') ? dec : ''
}

export const BIG_10_TO_BIG_16 = (_s: numeric): string => {
  const hex: Array<string | undefined> = []

  const sStr = (IS_SET(_s) && (((typeof _s === 'number') && !isNaN(_s)) || (_s !== 'NaN'))) ? `${_s}` : ''

  if (sStr === '0') {
    hex.push('0')
  } else {
    const dec = sStr.split('')
    const sum: number[] = []

    let i: number
    let s: number

    while (dec.length !== 0) {
      s = +(dec.shift() ?? 0)

      for (i = 0; (s !== 0) || i < sum.length; i += 1) {
        s += ((sum[i] ?? 0)) * 10
        sum[i] = s % 16
        s = (s - sum[i]) / 16
      }
    }

    while (sum.length !== 0) {
      hex.push(sum.pop()?.toString(16))
    }
  }

  return hex.join('')
}

export const FROM_BASE_10_TO_16 = (_: numeric): string => BIG_10_TO_BIG_16(_)

export const FROM_BASE_16_TO_10 = (_: numeric): string => BIG_16_TO_BIG_10(_)

export const FROM_BASE_16_TO_CARD_SERIAL = (_: numeric): string => {
  const s = TO_STRING(_).padStart(16, '0')
  const sLength = s.length
  return `${s.substring(sLength - 16, sLength - 8)} ${s.substring(sLength - 8)}`
}

export const FROM_BASE_10_TO_CARD_SERIAL = (_: numeric): string => FROM_BASE_16_TO_CARD_SERIAL(FROM_BASE_10_TO_16(_))

export const OPTIONS_TO_KEY_VALUES = (opts: Array<{ value: any, label: string }>): Record<string, string> => {
  const res: Record<string, string> = {}

  for (const { value, label } of opts) {
    res[TO_STRING(value)] = label
  }

  return res
}

export const TO_JSON = (v: any): string => {
  let res: string = ''

  if (IS_SET(v)) {
    try { res = JSON.stringify(v) } catch { }
  }

  return res
}

export const TO_ANY = (json?: string): any => {
  let res: string = ''

  if (IS_A_STRING_AND_NOT_EMPTY(json)) {
    try { res = JSON.parse(json ?? '') } catch { }
  }

  return res
}

export const TRIM_DATA = (_: { object: any, depth?: numeric }): any => {
  const o: any = _.object
  const depth = _.depth ?? Number.MAX_SAFE_INTEGER

  let res: any

  if (IS_SET(o)) {
    if (Array.isArray(o)) {
      res = o.map((row: any) => TRIM_DATA({ object: row, depth: TO_NUMBER(depth) - 1 }))
    } else if (typeof o === 'object') {
      Object.keys(o).forEach(k => {
        if (IS_SET(o[k])) {
          if (IS_NOT_SET(res)) {
            res = {}
          }

          res[k] = o[k]

          if (Array.isArray(o[k])) {
            res[k] = o[k].map((row: any) => TRIM_DATA({ object: row, depth: TO_NUMBER(depth) - 1 }))
          } else if (typeof o[k] === 'object') {
            if (o[k] instanceof Date) {
              res[k] = o[k].toISOString()
            } else {
              res[k] = TRIM_DATA({ object: o[k], depth: TO_NUMBER(depth) - 1 })
            }
          }
        }
      })
    } else {
      res = o
    }
  }

  return res
}

export const BIT_SET = (_: { on: numeric, at: numeric, to: 0 | 1 }): string => {
  const mask = 1 << TO_NUMBER(_.at)
  return TO_STRING((TO_NUMBER(_.on) & ~mask) | ((_.to << TO_NUMBER(_.at)) & mask))
}

export const BIT_KILL = (_: { on: numeric, at: numeric }): string => {
  const mask = 1 << TO_NUMBER(_.at)
  return TO_STRING((TO_NUMBER(_.on) & ~mask) | ((0 << TO_NUMBER(_.at)) & mask))
}

// BIG base SMALL number input
export const PARSE_INT_FROM_CUSTOM_BASE = (_: { input: string, base: string }): number => _.base.indexOf(_.input)

export const SET_CHAR_AT = (
  _: { input: string, at: numeric, set: string }
): string => `${_.input.substring(0, TO_NUMBER(_.at))}${_.set}${_.input.substring(TO_NUMBER(_.at) + _.set.length)}`

export const BYTE_ARRAY_LIKE_TO_BASE_256 = (o: any): string => {
  return [...(o ?? [])].map(v => BASE_CONVERT_BBSN(v, 10, 256)).join('')
}

export const BASE_256_TO_ARRAY_10 = (s: string): number[] => {
  const base = SYMBOLS_BASE_256
  return [...(s ?? '')].map((input: string) => PARSE_INT_FROM_CUSTOM_BASE({ input, base }))
}

export const BASE_256_TO_ARRAY_16 = (s: string): string[] => BASE_256_TO_ARRAY_10(s).map(FROM_BASE_10_TO_16)

export const ARRAY_LIKE_10_TO_ARRAY_16 = (b: any): string[] => [...(b ?? [])].map(v => FROM_BASE_10_TO_16(v))

export const BUFFER_TO_UUID = (b: any): string => {
  const input = [...(b ?? [])].slice(0, 16)
  const parts = [
    input.slice(0, 4),
    input.slice(4, 6),
    input.slice(6, 8),
    input.slice(8, 10),
    input.slice(10, 16)
  ]
  return parts.map(p => ARRAY_LIKE_10_TO_ARRAY_16(p).map(s => s.padStart(2, '0')).join('')).join('-')
}

export const SIGNED_TO_UNSIGNED = (_: { value: numeric, bits: numeric }): string => {
  const maxUnsignedValue = Math.pow(2, TO_NUMBER(_.bits))
  return (TO_NUMBER(_.value) < 0) ? TO_STRING(TO_NUMBER(_.value) + maxUnsignedValue) : TO_STRING(_.value)
}
export const UNSIGNED_TO_SIGNED = (_: { value: numeric, bits: numeric }): string => {
  const maxUnsignedValue = Math.pow(2, TO_NUMBER(_.bits))
  return (
    (TO_NUMBER(_.value) > TO_NUMBER((maxUnsignedValue / 2) - 1))
      ? TO_STRING(TO_NUMBER(_.value) - maxUnsignedValue)
      : TO_STRING(_.value)
  )
}

// Buffer.from( 'test' ).toString( 'ascii' )
// export const BYTE_ARRAY_LIKE_TO_STRING = (b: any): string => [...(b ?? [])].map(v => String.fromCharCode(v)).join('')

export const TO_ARRAY = <T> (_: any): T[] => {
  let res: T[] = []

  if (IS_AN_ARRAY_AND_NOT_EMPTY(_)) {
    res = _
  } else if (IS_A_SET_AND_NOT_EMPTY(_)) {
    res = [..._]
  } else if (IS_A_MAP_AND_NOT_EMPTY(_) || IS_AN_OBJECT_AND_NOT_EMPTY(_)) {
    const bIsAMap = IS_A_MAP(_)

    const numberKeyList: number[] = []
    const stringKeyList: string[] = []

    if (bIsAMap) {
      for (const k of _.keys()) {
        if (IS_NUMERIC(k)) {
          numberKeyList.push(TO_NUMBER(k))
        } else {
          stringKeyList.push(k)
        }
      }
    } else {
      for (const k of Object.keys(_)) {
        if (IS_NUMERIC(k)) {
          numberKeyList.push(TO_NUMBER(k))
        } else {
          stringKeyList.push(k)
        }
      }
    }

    numberKeyList.sort((a, b) => a - b)

    if (bIsAMap) {
      for (const k of numberKeyList) {
        res[k] = _.get(k)
      }

      for (const k of stringKeyList) {
        res.push(_.get(k))
      }
    } else {
      for (const k of numberKeyList) {
        res[k] = _[k]
      }

      for (const k of stringKeyList) {
        res.push(_[k])
      }
    }
  }

  return res
}

export const TO_ANY_ARRAY = (_: any): any[] => TO_ARRAY<any>(_)

export const IGNORE_DUPLICATES = (on: (element: any) => any): (elem: any) => boolean => {
  const already = new Set()

  return element => {
    const value = on(element)
    const bNew = !already.has(value)

    bNew && already.add(value)

    return bNew
  }
}

export const TO_UNIQUE_ARRAY = (_: { from: any[] | Set<any>, on?: (element: any) => any }): any[] => {
  const from = _.from

  const bFromArray = IS_AN_ARRAY_AND_NOT_EMPTY(from)

  const toArray: any[] = bFromArray ? from : (IS_A_SET_AND_NOT_EMPTY(from) ? [...from] : [])

  return IS_SET(_.on) ? toArray.filter(IGNORE_DUPLICATES(_.on)) : (bFromArray ? [...(new Set(toArray))] : toArray)
}

// used to retrieve the user roles (inherited from the symfony FOSUser projet)
export const FROM_DC2TYPE_ARRAY_OF_STRING_TO_ARRAY = (_dc2typeArrayString: any): string[] => {
  const dc2typeArrayString = TO_STRING(_dc2typeArrayString)

  const arrayResult: any[] = []

  if (dc2typeArrayString.at(0) === 'a') {
    const dc2typeArrayStringPartList = dc2typeArrayString.split(';')

    for (const part of dc2typeArrayStringPartList) {
      const partData = part.split(':')
      if (partData[0] === 's') {
        let s = ''
        try { s = JSON.parse(TO_STRING(partData[2])) } catch { }
        arrayResult.push(s)
      }
    }
  }

  return arrayResult
}

// used to store user roles (inherited from the symfony FOSUser projet)
export const FROM_ARRAY_OF_STRING_TO_DC2TYPE_ARRAY = (arrayOfString: string[]): string => {
  let dc2typeArrayString = 'a:0:{}'

  if (IS_AN_ARRAY_AND_NOT_EMPTY(arrayOfString)) {
    dc2typeArrayString = `a:${arrayOfString.length}:{`

    for (let i = 0; i < arrayOfString.length; i += 1) {
      const str = TO_STRING(arrayOfString[i])
      dc2typeArrayString += `i:${i};s:${str.length}:${JSON.stringify(str)};`
    }

    dc2typeArrayString += '}'
  }

  return dc2typeArrayString
}

const _utf8SerializeSize = (str: any): number => ~-encodeURI(TO_STRING(str)).split(/%..|./).length

const _getSerializeType = (inp: any): 'null' | 'boolean' | 'number' | 'string' | 'array' | 'function' | 'object' => {
  if (IS_NOT_SET(inp)) {
    return 'null'
  } else if (IS_A_BOOLEAN(inp)) {
    return 'boolean'
  } else if (IS_A_NUMBER(inp)) {
    return 'number'
  } else if (IS_A_STRING(inp)) {
    return 'string'
  } else if (IS_AN_ARRAY(inp)) {
    return 'array'
  } else if (IS_A_FUNCTION(inp)) {
    return 'function'
  }

  return 'object'
}

export const PHP_SERIALIZE = (mixedValue: any): string => {
  const type = _getSerializeType(mixedValue)

  const bBoolean = type === 'boolean'
  const bNumber = type === 'number'
  const bString = type === 'string'
  const bArray = type === 'array'
  const bFunction = type === 'function'
  const bObject = type === 'object'

  let res = ''

  if (bFunction) {
    res = ''
  } else if (bBoolean) {
    res = `b:${IS_ON(mixedValue) ? '1' : '0'}`
  } else if (bNumber) {
    const mixedValueNumber = TO_NUMBER(mixedValue)

    let mixedValueSerialized = TO_STRING(mixedValueNumber)

    if (isNaN(mixedValueNumber)) {
      mixedValueSerialized = 'NAN'
    } else if (!isFinite(mixedValueNumber)) {
      mixedValueSerialized = `${(mixedValueNumber < 0) ? '-' : ''}INF`
    }

    res = `${(Math.round(mixedValueNumber) === mixedValue) ? 'i' : 'd'}:${mixedValueSerialized}`
  } else if (bString) {
    res = `s:${_utf8SerializeSize(mixedValue)}:${JSON.stringify(mixedValue)}`
  } else if (bArray || bObject) {
    res = 'a'

    let count = 0

    let vals = ''

    for (const key of Object.keys(mixedValue)) {
      const ktype = _getSerializeType(mixedValue[key])

      if (ktype !== 'function') {
        const okey = IS_ON(key.match(/^[0-9]+$/)) ? TO_NUMBER(key) : key

        vals += PHP_SERIALIZE(okey) + PHP_SERIALIZE(mixedValue[key])

        count += 1
      }
    }

    res += `:${count}:{${vals}}`
  } else {
    res = 'N'
  }

  if (!bObject && !bArray) {
    res += ';'
  }

  return res
}

function _initSerializeCache (): any {
  const store: any[] = []
  // cache only first element, second is length to jump ahead for the parser
  const cache = (value: any): any => {
    store.push(value[0])

    return value
  }

  cache.get = (index: number): any => {
    if (index >= store.length) {
      throw RangeError(`Can't resolve reference ${index + 1}`)
    }

    return store[index]
  }

  return cache
}

function _expectSerializeType (str: string, cache: any): any {
  const types = /^(?:N(?=;)|[bidsSaOCrR](?=:)|[^:]+(?=:))/g
  const type = TO_ANY_ARRAY(types.exec(str))[0]

  if (IS_OFF(type)) {
    throw SyntaxError('Invalid input: ' + str)
  }

  switch (type) {
    case 'N':
      return cache([null, 2])
    case 'b':
      return cache(_expectSrializeBool(str))
    case 'i':
      return cache(_expectSerializeInt(str))
    case 'd':
      return cache(_expectSerializeFloat(str))
    case 's':
      return cache(_expectSerializeString(str))
    case 'S':
      return cache(_expectSerializeEscapedString(str))
    case 'a':
      return _expectSerializeArray(str, cache)
    case 'O':
      return _expectSerializeObject(str, cache)
    case 'C':
      return _expectSerializeClass(str, cache)
    case 'r':
    case 'R':
      return _expectSerializeReference(str, cache)
    default:
      throw SyntaxError(`Invalid or unsupported data type: ${TO_STRING(type)}`)
  }
}

function _expectSrializeBool (str: string): [boolean, number] {
  const reBool = /^b:([01]);/
  const [match, boolMatch] = TO_ANY_ARRAY(reBool.exec(str))

  if (IS_OFF(boolMatch)) {
    throw SyntaxError('Invalid bool value, expected 0 or 1')
  }

  return [boolMatch === '1', match.length]
}

function _expectSerializeInt (str: string): [number, number] {
  const reInt = /^i:([+-]?\d+);/
  const [match, intMatch] = TO_ANY_ARRAY(reInt.exec(str))

  if (IS_OFF(intMatch)) {
    throw SyntaxError('Expected an integer value')
  }

  return [TO_NUMBER(intMatch), match.length]
}

function _expectSerializeFloat (str: string): [number, number] {
  const reFloat = /^d:(NAN|-?INF|(?:\d+\.\d*|\d*\.\d+|\d+)(?:[eE][+-]\d+)?);/
  const [match, floatMatch] = TO_ANY_ARRAY(reFloat.exec(str))

  if (IS_OFF(floatMatch)) {
    throw SyntaxError('Expected a float value')
  }

  let floatValue

  switch (floatMatch) {
    case 'NAN':
      floatValue = Number.NaN
      break
    case '-INF':
      floatValue = Number.NEGATIVE_INFINITY
      break
    case 'INF':
      floatValue = Number.POSITIVE_INFINITY
      break
    default:
      floatValue = TO_NUMBER(floatMatch)
      break
  }

  return [floatValue, match.length]
}

function _readSerializeBytes (str: string, len: number, escapedString?: boolean): [string, number, number] {
  let bytes = 0
  let out = ''
  let c = 0

  const strLen = str.length

  let wasHighSurrogate = false
  let escapedChars = 0

  while (bytes < len && c < strLen) {
    let chr = str.charAt(c)
    const code = chr.charCodeAt(0)
    const isHighSurrogate = code >= 0xd800 && code <= 0xdbff
    const isLowSurrogate = code >= 0xdc00 && code <= 0xdfff

    if (IS_ON(escapedString) && (chr === '\\')) {
      chr = String.fromCharCode(parseInt(str.substring(c + 1, (c + 1) + 2), 16))

      escapedChars += 1

      // each escaped sequence is 3 characters. Go 2 chars ahead.
      // third character will be jumped over a few lines later
      c += 2
    }

    c++

    bytes += isHighSurrogate || (isLowSurrogate && wasHighSurrogate)
      // if high surrogate, count 2 bytes, as expectation is to be followed by low surrogate
      // if low surrogate preceded by high surrogate, add 2 bytes
      ? 2
      : code > 0x7ff
        // otherwise low surrogate falls into this part
        ? 3
        : code > 0x7f
          ? 2
          : 1

    // if high surrogate is not followed by low surrogate, add 1 more byte
    bytes += wasHighSurrogate && !isLowSurrogate ? 1 : 0

    out += chr
    wasHighSurrogate = isHighSurrogate
  }

  return [out, bytes, escapedChars]
}

function _expectSerializeString (str: string): [string, number] {
  // PHP strings consist of one-byte characters.
  // JS uses 2 bytes with possible surrogate pairs.
  // Serialized length of 2 is still 1 JS string character
  const reStrLength = /^s:(\d+):"/g // also match the opening " char
  const reRes = TO_ANY_ARRAY(reStrLength.exec(str))
  const match = TO_STRING(reRes[0])
  const byteLenMatch = TO_NUMBER(reRes[1])

  if (IS_OFF(match)) {
    throw SyntaxError('Expected a string value')
  }

  const len = TO_NUMBER(byteLenMatch)

  str = str.substring(match.length)

  const [strMatch, bytes] = _readSerializeBytes(str, len)

  if (bytes !== len) {
    throw SyntaxError(`Expected string of ${len} bytes, but got ${bytes}`)
  }

  str = str.substring(strMatch.length)

  // strict parsing, match closing "; chars
  if (!str.startsWith('";')) {
    throw SyntaxError('Expected ";')
  }

  return [strMatch, match.length + strMatch.length + 2] // skip last ";
}

function _expectSerializeEscapedString (str: string): [string, number] {
  const reStrLength = /^S:(\d+):"/g // also match the opening " char
  const reRes = TO_ANY_ARRAY(reStrLength.exec(str))
  const match = TO_STRING(reRes[0])
  const strLenMatch = TO_NUMBER(reRes[1])

  if (IS_OFF(match)) {
    throw SyntaxError('Expected an escaped string value')
  }

  const len = TO_NUMBER(strLenMatch)

  str = str.substring(match.length)

  const [strMatch, bytes, escapedChars] = _readSerializeBytes(str, len, true)

  if (bytes !== len) {
    throw SyntaxError(`Expected escaped string of ${len} bytes, but got ${bytes}`)
  }

  str = str.substring(strMatch.length + escapedChars * 2)

  // strict parsing, match closing "; chars
  if (!str.startsWith('";')) {
    throw SyntaxError('Expected ";')
  }

  return [strMatch, match.length + strMatch.length + 2] // skip last ";
}

function _expectSerializeKeyOrIndex (str: string): [string | number, number] {
  try { return _expectSerializeString(str) } catch { }
  try { return _expectSerializeEscapedString(str) } catch { }
  try {
    return _expectSerializeInt(str)
  } catch (err) {
    throw SyntaxError('Expected key or index')
  }
}

function _expectSerializeObject (str: string, cache: any): [any, number] {
  // O:<class name length>:"class name":<prop count>:{<props and values>}
  // O:8:"stdClass":2:{s:3:"foo";s:3:"bar";s:3:"bar";s:3:"baz";}
  const reObjectLiteral = /^O:(\d+):"([^"]+)":(\d+):\{/
  // const [objectLiteralBeginMatch, /* classNameLengthMatch */, className, propCountMatch] = TO_ANY_ARRAY(reObjectLiteral.exec(str))
  const reRes = TO_ANY_ARRAY(reObjectLiteral.exec(str))
  const objectLiteralBeginMatch = TO_STRING(reRes[0])
  // const classNameLengthMatch = TO_NUMBER(reRes[1])
  const className = TO_STRING(reRes[2])
  const propCountMatch = TO_NUMBER(reRes[3])

  if (IS_OFF(objectLiteralBeginMatch)) {
    throw SyntaxError('Invalid input')
  }

  if (className !== 'stdClass') {
    throw SyntaxError(`Unsupported object type: ${className}`)
  }

  let totalOffset = objectLiteralBeginMatch.length

  const propCount = TO_NUMBER(propCountMatch)
  const obj: any = {}
  cache([obj])

  str = str.substring(totalOffset)

  for (let i = 0; i < propCount; i += 1) {
    const prop = _expectSerializeKeyOrIndex(str)
    str = str.substring(prop[1])
    totalOffset += prop[1]

    const value = _expectSerializeType(str, cache)
    const v1 = TO_NUMBER(value[1])
    str = str.substring(v1)
    totalOffset += v1

    obj[prop[0]] = value[0]
  }

  // strict parsing, expect } after object literal
  if (str.charAt(0) !== '}') {
    throw SyntaxError('Expected }')
  }

  return [obj, totalOffset + 1] // skip final }
}

function _expectSerializeClass (str: string, cache: any): null {
  // // can't be well supported, because requires calling eval (or similar)
  // // in order to call serialized constructor name
  // // which is unsafe
  // // or assume that constructor is defined in global scope
  // // but this is too much limiting
  // throw Error('Not yet implemented')
  return null
}

function _expectSerializeReference (str: string, cache: any): [any, number] {
  const reRef = /^[rR]:([1-9]\d*);/
  const [match, refIndex] = TO_ANY_ARRAY(reRef.exec(str))

  if (IS_OFF(match)) {
    throw SyntaxError('Expected reference value')
  }

  return [cache.get(parseInt(refIndex, 10) - 1), match.length]
}

function _expectSerializeArray (str: string, cache: any): [any, number] {
  const reArrayLength = /^a:(\d+):{/
  const reRes = TO_ANY_ARRAY(reArrayLength.exec(str))
  const arrayLiteralBeginMatch = TO_STRING(reRes[0])
  const arrayLengthMatch = TO_NUMBER(reRes[1])

  if (!IS_NUMERIC(arrayLengthMatch)) {
    throw SyntaxError('Expected array length annotation')
  }

  str = str.substring(arrayLiteralBeginMatch.length)

  const array = _expectSerializeArrayItems(str, arrayLengthMatch, cache)

  // strict parsing, expect closing } brace after array literal
  const a1 = TO_NUMBER(array[1])
  if (str.charAt(a1) !== '}') {
    throw SyntaxError('Expected }')
  }

  return [array[0], arrayLiteralBeginMatch.length + a1 + 1] // jump over }
}

function _expectSerializeArrayItems (str: string, _iExpectedItems: int, cache: any): [any, number] {
  const iExpectedItems = TO_NUMBER(_iExpectedItems)
  let key
  let item
  let totalOffset = 0
  let hasContinousIndexes = true
  let lastIndex = -1
  let items: any = {}
  cache([items])

  for (let i = 0; i < iExpectedItems; i += 1) {
    key = _expectSerializeKeyOrIndex(str)

    const k0 = TO_NUMBER(key[0])

    hasContinousIndexes = hasContinousIndexes && (typeof key[0] === 'number') && (k0 === lastIndex + 1)
    lastIndex = k0

    const k1 = TO_NUMBER(key[1])
    str = str.substring(k1)
    totalOffset += k1

    // references are resolved immediately, so if duplicate key overwrites previous array index
    // the old value is anyway resolved
    // fixme: but next time the same reference should point to the new value
    item = _expectSerializeType(str, cache)
    const i1 = TO_NUMBER(item[1])
    str = str.substring(i1)
    totalOffset += i1

    items[key[0]] = item[0]
  }

  if (hasContinousIndexes) {
    items = Object.values(items)
  }

  return [items, totalOffset]
}

export const PHP_UNSERIALIZE = (str: string): any => {
  try {
    if (typeof str !== 'string') {
      return false
    }

    return _expectSerializeType(str, _initSerializeCache())[0]
  } catch (err) {
    console.error(err)
    return false
  }
}

export const FROM_MAP_TO_OBJECT = (m: Map<any, any>): any => {
  const o: any = {}
  for (const [key, value] of m) {
    o[TO_STRING(key)] = value
  }
  return o
}

export const FROM_OBJECT_TO_MAP = (o: any): Map<string, any> => {
  const m = new Map<string, any>()
  for (const key of Object.keys(o)) {
    m.set(key, o[key])
  }
  return m
}

export const TO_SAFE_SQL_COLUMN_REF = (str: any): string => TO_STRING(str).replace(/[^0-9a-zA-Z_.]/g, '')

export const TO_SAFE_SQL_OPERATOR = (str: any): string => TO_STRING(str).replace(/[^0-9a-zA-Z_.<>=! ~&|]/g, '')

// * nodejs only
// export const TO_BUFFER = (_: any): Buffer => ArrayBuffer.isView(_) ? Buffer.from(_.buffer, _.byteOffset, _.byteLength) : Buffer.from(_)

export const FROM_NAN_TO_NULL = <T> (v: T): T | null => Number.isNaN(v) ? null : v

export const FROM_NOT_NUMERIC_TO_NULL_FILTER_VALUE = <T> (v: T): T | '§§null§§' => IS_NUMERIC(v) ? v : '§§null§§'

export const EXPORT_R = (v: any): boolean | number | string | any[] | any[][] | Record<string, any> | null => {
  let res: boolean | number | string | any[] | any[][] | Record<string, any> | null = null

  if (IS_A_BOOLEAN(v) || IS_A_NUMBER(v) || IS_A_STRING(v)) {
    res = v
  } else if (IS_A_FUNCTION(v)) {
    res = v.name
  } else if (IS_AN_ARRAY(v)) {
    res = v.map(EXPORT_R)
  } else if ((v instanceof DateTime) && v.isValid) {
    res = v.toISO()
  } else if (IS_A_DATE_AND_NOT_EMPTY(v)) {
    res = v.toISOString()
  } else if (IS_A_BIGINT(v)) {
    res = TO_STRING(v)
  } else if (IS_A_SET(v)) {
    res = [...v].map(EXPORT_R)
  } else if (IS_A_MAP(v)) {
    res = [...v].map(([key, value]) => [EXPORT_R(key), EXPORT_R(value)])
  } else if (IS_AN_OBJECT(v)) {
    res = {}

    for (const [key, value] of Object.entries(v)) {
      res[key] = EXPORT_R(value)
    }
  }

  return res
}

export const IMPORT_CS_LIST = (s: string): string[] => s.split(',')

export const IMPORT_CS_SET = (s: string): Set<string> => new Set(IMPORT_CS_LIST(s))

export const TO_NORMALIZED = <T> (_: { min: T, v: T, max: T }): T => {
  let res = _.v

  if (_.v < _.min) {
    res = _.min
  } else if (_.max < _.v) {
    res = _.max
  }

  return res
}

export const TO_MAX = <T> (_of: [T, ...T[]]): T => {
  let res: T = _of[0]

  for (const v of _of) {
    if (res < v) {
      res = v
    }
  }

  return res
}

export const TO_MIN = <T> (_of: [T, ...T[]]): T => {
  let res: T = _of[0]

  for (const v of _of) {
    if (v < res) {
      res = v
    }
  }

  return res
}

export const TO_ABS = <T> (v: T): T => {
  let res: any = v

  if (IS_A_NUMBER(v)) {
    res = Math.abs(v)
  } else if (IS_A_BIGINT(v) || IS_A_STRING(v)) {
    const bi = IS_A_BIGINT(v) ? v : TO_BIGINT(v)

    if (IS_SET(bi)) {
      res = (bi < 0n) ? -bi : bi

      if (IS_A_STRING(v)) {
        res = TO_STRING(res)
      }
    }
  }

  return res
}
