import { IS_SET } from './check.util'

export interface ExceptionResult { exception: any, result: any }

export const _resultOf = async (p: Promise<any>, errorExt?: any): Promise<ExceptionResult> => {
  const [exception, result] = await p
    .then(data => [null, data])
    .catch(err => {
      if (IS_SET(errorExt)) {
        Object.assign(err, errorExt)
      }
      return [err, undefined]
    })
  return { exception, result }
}

export const READ_FILE_CONTENT = async (file: any): Promise<ExceptionResult> => {
  const reader = new FileReader()
  const res = await _resultOf(new Promise((resolve, reject) => {
    reader.onload = event => { resolve(event.target?.result) }
    reader.onerror = error => { reject(error) }
    reader.readAsText(file)
  }))
  return res
}
