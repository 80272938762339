import { Component, OnInit } from "@angular/core"
import { MatButtonModule } from "@angular/material/button"
import { RouterModule } from "@angular/router"
import { AppDisplay } from "src/app/type/display"

@Component({
  imports: [RouterModule, MatButtonModule],
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
  standalone: true,
})
export class AppUnauthorizedPage implements OnInit {
  display = new AppDisplay({
    cache: {
      this_account_is_not_authorized_to_access_this_application_: '',
      contact_your_system_administrator_or_try_with_another_account_: '',
      try_with_another_account: ''
    },
    entries: {
      this_account_is_not_authorized_to_access_this_application_: {
        default: 'This account is not authorized to access this application.',
        fr: 'Ce compte n\'est pas autorisé à accéder à cette application.',
        es: 'Esta cuenta no está autorizada para acceder a esta aplicación.',
        it: 'Questo account non è autorizzato ad accedere a questa applicazione.'
      },
      contact_your_system_administrator_or_try_with_another_account_: {
        default: 'Contact your system administrator or try with another account.',
        fr: 'Contactez votre administrateur système ou essayez avec un autre compte.',
        es: 'Contacte a su administrador de sistemas o intente con otra cuenta.',
        it: 'Contattare l\'amministratore di sistema o provare con un altro account.'
      },
      try_with_another_account: {
        default: 'Try with another account',
        fr: 'Essayez avec un autre compte',
        es: 'Intente con otra cuenta',
        it: 'Prova con un altro account'
      }
    }
  })

  ngOnInit (): void {
    this.display.updateAll()
  }
}