import { DateTime, Settings } from 'luxon'
import { environment } from '../../environments/environment'
import { APP_UUID } from '../app-uuid'
import { type DictionaryEntries } from '../core/context/dictionary.service'
import { type int } from '../type/int'
import { type AppOption } from '../type/app-option'
import { TO_NUMBER, TO_STRING } from './convert.util'
import { GET_ENV_TIMEZONE } from './env.util'

export const TIMEZONE_STORAGE_KEY = `_app_${APP_UUID}_timezone`

export const GET_INITIAL_TZ = async (): Promise<string> => {
  return TO_STRING(localStorage.getItem(TIMEZONE_STORAGE_KEY) ?? (await GET_ENV_TIMEZONE()) ?? environment.timezone)
}
export const SET_TZ = (tz: string): void => { Settings.defaultZone = tz }
export const GET_TZ = (): string => TO_STRING(Settings.defaultZone.name)

export const LUXON_FORMAT_YM = 'y-MM'
export const LUXON_FORMAT_YMD = 'y-MM-dd'
export const LUXON_FORMAT_HI = 'HH:mm'
export const LUXON_FORMAT_HIS = 'HH:mm:ss'
export const LUXON_FORMAT_YMD_HIS = 'y-MM-dd HH:mm:ss'
export const LUXON_FORMAT_YMD_HIS_Z = 'y-MM-dd HH:mm:ss (z)'
export const LUXON_FORMAT_LOCAL_YMD = 'D'
export const LUXON_FORMAT_LOCAL_HI = 'T'
export const LUXON_FORMAT_LOCAL_HIS = 'TT'
export const LUXON_FORMAT_LOCAL_YMD_HI = 'D T'
export const LUXON_FORMAT_LOCAL_YMD_HIS = 'D TT'
export const LUXON_FORMAT_LOCAL_YMD_HIS_Z = 'D TT (z)'

export const NOW_UTC = (): DateTime => DateTime.utc()
export const NOW = (): DateTime => DateTime.local()
export const NOW_APP = (): DateTime => NOW().setZone(GET_TZ() ?? 'UTC')
export const NOW_MS = (): number => NOW().toMillis()
export const NOW_UNIX = (): number => Math.trunc(NOW().toSeconds())

export const MYSQL_DATETIME_NOW_UTC = (): string => NOW_UTC().toFormat(LUXON_FORMAT_YMD_HIS)

export const WAIT_MS = async (ms: int): Promise<void> => { await new Promise(resolve => setTimeout(resolve, TO_NUMBER(ms))) }

export const TIMESTAMP_1997_UTC = DateTime.fromObject({ year: 1997 }, { zone: 'UTC' }).startOf('year').toSeconds()

export const SECONDS_IN_ONE_DAY = 60n * 60n * 24n // 86400
export const MILLISECONDS_IN_ONE_DAY = SECONDS_IN_ONE_DAY * 1000n // 86400000

export const KEY_DICTIONARY_ENTRY_PERIOD_PRESET_NAME: DictionaryEntries = {
  today: { default: 'Today', fr: 'Aujourd\'hui', es: 'Hoy', it: 'Oggi', de: 'Heute', pt: 'Hoje', ja: '今日', ko: '오늘', zh: '今天' },
  yesterday: { default: 'Yesterday', fr: 'Hier', es: 'Ayer', it: 'Ieri', de: 'Gestern', pt: 'Ontem', ja: '昨日', ko: '어제', zh: '昨天' },
  last_7_days: { default: 'Last 7 days', fr: '7 derniers jours', es: 'Últimos 7 días', it: 'Ultimi 7 giorni', de: 'Letzte 7 Tage', pt: 'Últimos 7 dias', ja: '過去7日間', ko: '지난 7일', zh: '过去7天' },
  last_30_days: { default: 'Last 30 days', fr: '30 derniers jours', es: 'Últimos 30 días', it: 'Ultimi 30 giorni', de: 'Letzte 30 Tage', pt: 'Últimos 30 dias', ja: '過去30日間', ko: '지난 30일', zh: '过去30天' },
  last_90_days: { default: 'Last 90 days', fr: '90 derniers jours', es: 'Últimos 90 días', it: 'Ultimi 90 giorni', de: 'Letzte 90 Tage', pt: 'Últimos 90 dias', ja: '過去90日間', ko: '지난 90일', zh: '过去90天' },
  last_180_days: { default: 'Last 180 days', fr: '180 derniers jours', es: 'Últimos 180 días', it: 'Ultimi 180 giorni', de: 'Letzte 180 Tage', pt: 'Últimos 180 dias', ja: '過去180日間', ko: '지난 180일', zh: '过去180天' },
  last_365_days: { default: 'Last 365 days', fr: '365 derniers jours', es: 'Últimos 365 días', it: 'Ultimi 365 giorni', de: 'Letzte 365 Tage', pt: 'Últimos 365 dias', ja: '過去365日間', ko: '지난 365일', zh: '过去365天' },
  '2_days_ago': { default: '2 days ago', fr: 'Il y a 2 jours', it: '2 giorni fa', es: 'Hace 2 días' },
  '7_days': { default: '7 days', fr: '7 jours', it: '7 giorni', es: '7 días' },
  '30_days': { default: '30 days', fr: '30 jours', it: '30 giorni', es: '30 días' },
  '90_days': { default: '90 days', fr: '90 jours', it: '90 giorni', es: '90 días' },
  '180_days': { default: '180 days', fr: '180 jours', it: '180 giorni', es: '180 días' },
  '365_days': { default: '365 days', fr: '365 jours', it: '365 giorni', es: '365 días' }
}

export interface PERIOD_FROM_TO {
  from: DateTime
  to: DateTime
}

export const GET_DEFAULT_PERIOD_PRESET_LIST = (): Array<AppOption<PERIOD_FROM_TO>> => {
  const mNow = NOW_APP()

  const mTodayStart = mNow.startOf('day')
  const mTodayStop = mNow.endOf('day')

  const mYesterday = mNow.minus({ days: 1 })

  const mYesterdayStart = mYesterday.startOf('day')
  const mYesterdayStop = mYesterday.endOf('day')

  const m7DaysAgoStart = mNow.minus({ days: 7 }).startOf('day')
  const m30DaysAgoStart = mNow.minus({ days: 30 }).startOf('day')
  const m90DaysAgoStart = mNow.minus({ days: 90 }).startOf('day')
  const m180DaysAgoStart = mNow.minus({ days: 180 }).startOf('day')
  const m365DaysAgoStart = mNow.minus({ days: 365 }).startOf('day')

  return [
    { label: '', key: 'today', value: { from: mTodayStart, to: mTodayStop } },
    { label: '', key: 'yesterday', value: { from: mYesterdayStart, to: mYesterdayStop } },
    { label: '', key: 'last_7_days', value: { from: m7DaysAgoStart, to: mYesterdayStop } },
    { label: '', key: 'last_30_days', value: { from: m30DaysAgoStart, to: mYesterdayStop } },
    { label: '', key: 'last_90_days', value: { from: m90DaysAgoStart, to: mYesterdayStop } },
    { label: '', key: 'last_180_days', value: { from: m180DaysAgoStart, to: mYesterdayStop } },
    { label: '', key: 'last_365_days', value: { from: m365DaysAgoStart, to: mYesterdayStop } }
  ]
}
