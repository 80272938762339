import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { inject, Injector, NgModule } from '@angular/core'
import { type CanMatchFn, type Route, Router, RouterModule, type Routes, type UrlTree } from '@angular/router'
import { catchError, combineLatestWith, lastValueFrom, type Observable, of, type OperatorFunction, pipe, switchMap, timeout } from 'rxjs'
import { environment } from 'src/environments/environment'
import { AuthService } from './core/auth/auth.service'
import { LocaleService } from './core/context/locale.service'
import { OKTA_AUTH, OKTA_CONFIG } from './core/okta/okta.config'
import { HttpService } from './core/request/http.service'
import { AppLoadingPage } from './page/loading/loading.component'
import { AppLoggedOutPage } from './page/logged-out/logged-out.component'
import { AppUnexpectedSynapseErrorPage } from './page/synapse-error/synapse-error.component'
import { AppUnauthorizedPage } from './page/unauthorized/unauthorized.component'
import { type AppOption } from './type/app-option'
import { RESTRICTED_TO_SUPER_ADMIN_SET } from './util/auth.util'
import { IS_A_SET_AND_NOT_EMPTY, IS_A_STRING_AND_NOT_EMPTY, IS_OFF, IS_ON, IS_SET } from './util/check.util'
import { TO_ANY_ARRAY, TO_STRING } from './util/convert.util'
import { IS_ENV_RSS } from './util/env.util'

const pageHome = async (): Promise<any> => (await import('./page/home/home.module')).HomeModule
const pageStatistics = async (): Promise<any> => (await import('./page/statistics/statistics.module')).StatisticsModule
const pageAlerts = async (): Promise<any> => (await import('./page/alerts/alerts.module')).AlertsModule
const pageSam = async (): Promise<any> => (await import('./page/sam/sam.module')).SamModule
const pageEquipment = async (): Promise<any> => (await import('./page/equipment/equipment.module')).EquipmentModule
const pageParameters = async (): Promise<any> => (await import('./page/parameters/parameters.module')).ParametersModule
const pageReports = async (): Promise<any> => (await import('./page/reports/reports.module')).ReportsModule
const pageSystem = async (): Promise<any> => (await import('./page/system/system.module')).SystemModule
const pageAccounts = async (): Promise<any> => (await import('./page/accounts/accounts.module')).AccountsModule
const pageProfile = async (): Promise<any> => (await import('./page/profile/profile.module')).ProfileModule
const pageLogin = async (): Promise<any> => (await import('./page/login/login.module')).LoginModule
const pageNotFound = async (): Promise<any> => (await import('./page/page-not-found/page-not-found.module')).PageNotFoundModule

/**
 * Forbid access to login page if Synapse is available
 * @param _
 * @returns allowed access to login page or redirect to home page
 */
export const RSS_LOCAL_LOGIN_CAN_MATCH: CanMatchFn = async (_): Promise<boolean | UrlTree> => {
  const _this = {
    AuthService: inject(AuthService),
    Router: inject(Router),
  }

  let res: boolean | UrlTree = _this.AuthService.bLoggedIn

  if (_this.AuthService.bAllowLocalLogin && !res) {
    res = true
  } else {
    res = _this.Router.parseUrl('/')
  }

  return res
}

const expectSynapseError = <T> (handler: (err: any, caught?: Observable<T>) => Observable<boolean>): OperatorFunction<T, boolean> => {
  return pipe(
    timeout({
      // * 15 seconds delay before timeout
      first: 15e3,
      with: () => of(new Error('SYNAPSE_TIMEOUT')),
    }),
    switchMap<any, Observable<boolean>>(
      // ? This case is not supposed to happen and represents an anomaly
      // ? because Synapse is blocking requests comming from JS (in case of requesting web page)
      // ? If requesting the Oauth API, we are not doing a valid request here because no action is expected at this moment
      () => of(false)
    ),
    catchError<any, Observable<boolean>>(handler)
  )
}

export const AUTH_GUARD_CAN_MATCH: CanMatchFn = async (route): Promise<boolean | UrlTree> => {
  const _this = {
    Router: inject(Router),
    AuthService: inject(AuthService),
    LocaleService: inject(LocaleService),
    Injector: inject(Injector),
    HttpClient: inject(HttpClient),
    HttpService: inject(HttpService),
  }
  let error: Error | undefined
  let res: boolean | UrlTree = _this.AuthService.bLoggedIn
  let bSynapseSuccess = false
  let bRedirectToAuthLoadingPage = false

  const bRss = await IS_ENV_RSS()

  if (IS_OFF(res)) {
    if (bRss) {
      const oktaAuth = await _this.Injector.get(OKTA_AUTH)
      const oktaConfig = await _this.Injector.get(OKTA_CONFIG)

      const synapseProxyWorking$ = _this.HttpClient.get(oktaAuth.options.issuer).pipe(
        expectSynapseError((err: Error) => {
          console.log('oktaAuth.options.issuer', oktaAuth.options.issuer)

          // ? synapse error timeout
          const bTimeout = err.message === 'SYNAPSE_TIMEOUT'

          // ? synapse error 404
          const b404 = (err instanceof HttpErrorResponse) && (err.status === 404)

          if (bTimeout) {
            console.log('_❌_UNEXPECTED_SYNAPSE_TIMEOUT_USING_PROXY', err)
          } else if (b404) {
            console.log('_❌_UNEXPECTED_SYNAPSE_404_USING_PROXY', err)
          } else {
            console.log('_✅_EXPECTED_SYNAPSE_PROXY_RESULT_OR_NO_PROXY', err)
          }

          const bUnexpectedError = bTimeout || b404

          return of(!bUnexpectedError)
        })
      )

      let synpaseApiWorking$: Observable<boolean> = of(false)

      if (IS_A_STRING_AND_NOT_EMPTY(oktaAuth.options.tokenUrl)) {
        synpaseApiWorking$ = _this.HttpClient.post(
          oktaAuth.options.tokenUrl,
          {},
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Accept: 'application/json',
              Authorization: `Basic ${btoa(`${oktaAuth.options.clientId}:`)}`,
              'Cache-Control': 'no-cache',
            },
          }
        ).pipe(
          expectSynapseError((err: Error) => {
            console.log('oktaAuth.options.tokenUrl', oktaAuth.options.tokenUrl)

            // ? synapse error 400 or 401
            const bExpectedError = (err instanceof HttpErrorResponse) && ((err.status === 400) || (err.status === 401))

            if (bExpectedError) {
              console.log('_✅_EXPECTED_SYNAPSE_400_OR_401', err)
            } else {
              console.log('_❌_UNEXPECTED_SYNAPSE_ERROR', err)
            }

            return of(bExpectedError)
          })
        )
      }

      const synpaseAvailability$ = synapseProxyWorking$.pipe(
        combineLatestWith(synpaseApiWorking$),
        switchMap(([bProxyAvailable, bApiAvailable]) => of(bProxyAvailable && bApiAvailable)),
        catchError(() => of(false))
      )

      const bSynapseAvailable = await lastValueFrom(synpaseAvailability$)

      // * for rss, okta, synapse, set diffrent errors or detect redirection cases
      if (!bSynapseAvailable) {
        error = new Error('TO_LOGIN')
      } else if (oktaAuth.isLoginRedirect()) {
        await oktaConfig.onAuthResume(oktaAuth)

        const token = oktaAuth.getAccessToken()
        if (IS_A_STRING_AND_NOT_EMPTY(token)) {
          await _this.AuthService.updateToken(token)

          const options = _this.AuthService.getHttpOptionsWithBearerTokenAndContentTypeJson()

          await _this.HttpService.post({ path: '/synapse_auth', body: {}, options })
        }

        bRedirectToAuthLoadingPage = true
      } else if (!(await oktaAuth.isAuthenticated({ onExpiredToken: 'renew' }))) {
        oktaAuth.setOriginalUri(`/${route.path ?? ''}`)

        await oktaConfig.onAuthRequired(oktaAuth)

        bRedirectToAuthLoadingPage = true
      } else {
        try {
          const token = await oktaAuth.getOrRenewAccessToken()

          if (IS_A_STRING_AND_NOT_EMPTY(token)) {
            await _this.AuthService.updateToken(token)

            bSynapseSuccess = true
          } else {
            error = new Error('SYNAPSE_ERROR')
          }
        } catch (err) {
          error = err as Error
        }
      }
    } else {
      error = new Error('TO_LOGIN')
    }
  }

  // * handle cases where res should be a UrlTree to redirect the user
  // * instead of a boolean allowing or denying access to the requested route
  if (IS_SET(error)) {
    await _this.AuthService.logOut()

    const errorMessage = TO_STRING(error?.message ?? 'Okta callback is not implemented yet')

    if ((errorMessage === 'TO_LOGIN') || !bRss) {
      // ? Synapse is not available or not working so we allow the local login
      _this.AuthService.bAllowLocalLogin = true

      res = _this.Router.parseUrl('/login')
    } else {
      res = _this.Router.parseUrl('/synapse-error')
    }
  } else if (bRedirectToAuthLoadingPage) {
    res = _this.Router.parseUrl('/loading')
  } else if (IS_ON(res) || bSynapseSuccess) {
    if (bRss) {
      await _this.AuthService.refreshCurrentUser()

      const user = _this.AuthService.currentUserDataBS.value

      const roleSet = new Set(TO_ANY_ARRAY(user?.roleList).map(TO_STRING))

      res = IS_A_SET_AND_NOT_EMPTY(roleSet)

      if (!res) {
        await _this.AuthService.logOut()
      }

      const path = route.path

      if (res && IS_SET(path) && RESTRICTED_TO_SUPER_ADMIN_SET.has(path)) {
        res = roleSet.has('IS_SUPER_ADMIN')
      }
    }
  }

  return res
}

export const GET_APP_ROUTING_DEFAULT_NAVIGATION_ENTRY_LIST = async (): Promise<AppOption[]> => {
  const bRss = await IS_ENV_RSS()

  return [
    ...(
      bRss
        ? [{ key: 'alerts', value: '/alerts', label: '', svgIcon: 'bell' }]
        : [{ key: 'home', value: '/home', label: '', svgIcon: 'home', bIconOnly: true }]
    ),
    { key: 'sam', value: '/sam', label: '', svgIcon: 'sim' },
    ...(bRss ? [{ key: 'equipment', value: '/equipment', label: '', svgIcon: 'network' }] : []),
    { key: 'parameters', value: '/parameters', label: '', svgIcon: 'cogs' },
    ...(
      bRss
        ? [{ key: 'statistics', value: '/statistics', label: '', svgIcon: 'finance' }]
        : [{ key: 'reports', value: '/reports', label: '', svgIcon: 'finance' }]
    ),
    { key: 'system', value: '/system', label: '', svgIcon: 'clipboard-text' },
    { key: 'accounts', value: '/accounts', label: '', svgIcon: 'account-multiple' },
  ]
}

const routeMap = new Map<string, Route>([
  ['login', { path: 'login', loadChildren: pageLogin }],
  ['home', { path: 'home', loadChildren: pageHome, canMatch: [AUTH_GUARD_CAN_MATCH] }],
  ['overview', { path: 'overview', loadChildren: pageHome, canMatch: [AUTH_GUARD_CAN_MATCH] }],
  ['alerts', { path: 'alerts', loadChildren: pageHome, canMatch: [AUTH_GUARD_CAN_MATCH] }],
  ['sam', { path: 'sam', loadChildren: pageSam, canMatch: [AUTH_GUARD_CAN_MATCH] }],
  ['equipment', { path: 'equipment', loadChildren: pageEquipment, canMatch: [AUTH_GUARD_CAN_MATCH] }],
  ['parameters', { path: 'parameters', pathMatch: 'full', loadChildren: pageParameters, canMatch: [AUTH_GUARD_CAN_MATCH] }],
  ['reports', { path: 'reports', loadChildren: pageReports, canMatch: [AUTH_GUARD_CAN_MATCH] }],
  ['statistics', { path: 'statistics', loadChildren: pageStatistics, canMatch: [AUTH_GUARD_CAN_MATCH] }],
  ['system', { path: 'system', loadChildren: pageSystem, canMatch: [AUTH_GUARD_CAN_MATCH] }],
  ['accounts', { path: 'accounts', loadChildren: pageAccounts, canMatch: [AUTH_GUARD_CAN_MATCH] }],
  ['profile', { path: 'profile', loadChildren: pageProfile, canMatch: [AUTH_GUARD_CAN_MATCH] }],
  ['404', { path: '404', loadChildren: pageNotFound, canMatch: [AUTH_GUARD_CAN_MATCH] }],
  ['loading', { path: 'loading', component: AppLoadingPage }],
  ['', { path: '', pathMatch: 'full', redirectTo: '/overview' }],
])

if (TO_STRING(environment.context).toLowerCase() === 'rss') {
  routeMap.set('overview', { path: 'overview', loadChildren: pageStatistics, canMatch: [AUTH_GUARD_CAN_MATCH] })
  routeMap.set('alerts', { path: 'alerts', loadChildren: pageAlerts, canMatch: [AUTH_GUARD_CAN_MATCH] })
  routeMap.set('login', { path: 'login', loadChildren: pageLogin, canMatch: [RSS_LOCAL_LOGIN_CAN_MATCH] })
  routeMap.set('unauthorized', { path: 'unauthorized', component: AppUnauthorizedPage })
  routeMap.set('synapse-error', { path: 'synapse-error', component: AppUnexpectedSynapseErrorPage })
  routeMap.set('logged-out', { path: 'logged-out', component: AppLoggedOutPage })
  routeMap.set('', { path: '', pathMatch: 'full', redirectTo: '/alerts' })
}

routeMap.set('**', { path: '**', redirectTo: '/404' })

const routeList: Routes = [...routeMap.values()]

@NgModule({
  imports: [RouterModule.forRoot(routeList, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
