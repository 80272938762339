import { type FromReadonlyArray } from '../type/meta'

export const THEME_STYLES = [
  'danger',
  'error',
  'warning',
  'warn',
  'info',
  'success',
  'ok',
  'accent',
  'primary',
  'default',
  'dark',
  'warn_subtle',
  'warning_subtle',
  'error_subtle',
  'danger_subtle'
] as const
export const LEVELS = ['danger', 'warn', 'info', 'success'] as const

export type THEME_TYPE = FromReadonlyArray<typeof THEME_STYLES>
export type LEVEL_TYPE = FromReadonlyArray<typeof LEVELS>
