import { Injectable } from '@angular/core'
import { DateTime } from 'luxon'
import { BehaviorSubject } from 'rxjs'
import { environment } from '../../../environments/environment'
import { type AppOption } from '../../type/app-option'
import { IS_A_BIGINT, IS_A_NUMBER, IS_A_STRING_AND_NOT_EMPTY, IS_INT, IS_NUMERIC, IS_ON, IS_SET } from '../../util/check.util'
import { OPTIONS_TO_KEY_VALUES, TO_BIGINT, TO_NUMBER, TO_STRING } from '../../util/convert.util'
import { GET_ENV_APP_CONTEXT, IS_ENV_CDMX, IS_ENV_MONTREAL, IS_ENV_SNCB } from '../../util/env.util'
import { GET_LOCALE } from '../../util/space.util'
import { GET_TZ, LUXON_FORMAT_LOCAL_HI, LUXON_FORMAT_LOCAL_HIS, LUXON_FORMAT_LOCAL_YMD, LUXON_FORMAT_LOCAL_YMD_HI, LUXON_FORMAT_LOCAL_YMD_HIS, LUXON_FORMAT_LOCAL_YMD_HIS_Z, NOW_APP } from '../../util/time.util'

export interface LocaleServiceLocalizeMParams {
  input?: any
  timestampMs?: any
  epoch?: any
  inTz?: string
  inFormat?: string
  outTz?: string
  outFormat?: string
}

@Injectable({ providedIn: 'root' })
export class LocaleService {
  mLastLocaleChangeBS = new BehaviorSubject<DateTime | undefined>(undefined)

  localeBS = new BehaviorSubject<string>(GET_LOCALE() ?? environment.locale ?? 'en-gb')

  mLastTzChangeBS = new BehaviorSubject<DateTime | undefined>(undefined)

  tzBS = new BehaviorSubject<string>(GET_TZ() ?? environment.timezone ?? 'Europe/Paris')

  geoOptions: AppOption[] = [
    { label: 'Belgique', value: 'be' },
    { label: 'Canada', value: 'ca' },
    { label: 'France', value: 'fr' },
    { label: 'Italia', value: 'it' },
    { label: 'México', value: 'mx' },
    { label: 'United Kingdom', value: 'gb' }
  ]

  geoName = OPTIONS_TO_KEY_VALUES(this.geoOptions)

  langOptionList: AppOption[] = [
    { label: 'English', value: 'en' },
    { label: 'Español', value: 'es' },
    { label: 'Français', value: 'fr' },
    { label: 'Italiano', value: 'it' }
  ]

  langName = OPTIONS_TO_KEY_VALUES(this.langOptionList)

  supportedLocaleList = ['en-gb', 'es-mx', 'fr-fr', 'it-it']

  tzOptions: AppOption[] = [
    { label: 'UTC', value: 'UTC' },
    { label: 'Bruxelles', value: 'Europe/Brussels' },
    { label: 'CDMX', value: 'America/Mexico_City' },
    { label: 'London', value: 'Europe/London' },
    { label: 'Montréal', value: 'America/Montreal' },
    { label: 'Paris', value: 'Europe/Paris' },
    { label: 'Puebla', value: 'America/Mexico_City' },
    { label: 'Roma', value: 'Europe/Rome' }
  ]

  tzName = OPTIONS_TO_KEY_VALUES(this.tzOptions)

  authorizationOptions: AppOption[] = [
    { label: 'CV', value: '1' },
    { label: 'CL', value: '2' },
    { label: 'CP', value: '4' },
    { label: 'CPP', value: '8' }
  ]

  valueToAuth = OPTIONS_TO_KEY_VALUES(this.authorizationOptions)

  static defaultLocale = 'en-gb'

  async getLocaleOptionList (): Promise<AppOption[]> {
    const localeOptionListPromiseList = this.supportedLocaleList.map(async locale => {
      const langName = await this.getLangName({ locale })
      const bLangName = IS_A_STRING_AND_NOT_EMPTY(langName)
      const geoName = this.getGeoName({ locale })
      const bGeoName = IS_A_STRING_AND_NOT_EMPTY(geoName)
      return {
        value: locale,
        label: (bLangName || bGeoName) ? `${langName}${bGeoName ? ` (${geoName})` : ''}` : ''
      }
    })

    const localeOptionList = await Promise.all(localeOptionListPromiseList)

    return localeOptionList
  }

  async getLocaleNameRecord (): Promise<Record<string, string>> {
    return OPTIONS_TO_KEY_VALUES(await this.getLocaleOptionList())
  }

  async getMainLocaleFromLang (_?: {
    bCdmx?: boolean
    bMontreal?: boolean
    bSncb?: boolean
    context?: string
    override?: any
  }): Promise<Record<string, string>> {
    let _bCdmx = _?.bCdmx
    const bEnvCdmxIsSet = IS_SET(_bCdmx)

    let _bMontreal = _?.bMontreal
    const bEnvMontrealIsSet = IS_SET(_bMontreal)

    let _bSncb = _?.bSncb
    const bEnvSncbIsSet = IS_SET(_bSncb)

    let context: string | undefined

    if (!bEnvCdmxIsSet && !bEnvMontrealIsSet && !bEnvSncbIsSet) {
      context = await GET_ENV_APP_CONTEXT(_)

      if (!bEnvCdmxIsSet) {
        _bCdmx = await IS_ENV_CDMX({ context })
      }

      if (!bEnvMontrealIsSet) {
        _bMontreal = await IS_ENV_MONTREAL({ context })
      }

      if (!bEnvSncbIsSet) {
        _bSncb = await IS_ENV_SNCB({ context })
      }
    }

    return {
      en: 'en-gb', // en-us (ENV_USA)
      es: IS_ON(_bCdmx) ? 'es-mx' : 'es-es',
      fr: IS_ON(_bMontreal) ? 'fr-ca' : (IS_ON(_bSncb) ? 'fr-be' : 'fr-fr'),
      it: 'it-it'
    }
  }

  async getSupportedTzList (_?: {
    bCdmx?: boolean
    bMontreal?: boolean
    bSncb?: boolean
    context?: string
    override?: any
  }): Promise<string[]> {
    let bCdmx = _?.bCdmx
    const bEnvCdmxIsSet = IS_SET(bCdmx)

    let bMontreal = _?.bMontreal
    const bEnvMontrealIsSet = IS_SET(bMontreal)

    let bSncb = _?.bSncb
    const bEnvSncbIsSet = IS_SET(bSncb)

    let context: string | undefined

    if (!bEnvCdmxIsSet && !bEnvMontrealIsSet && !bEnvSncbIsSet) {
      context = await GET_ENV_APP_CONTEXT(_)

      if (!bEnvCdmxIsSet) {
        bCdmx = await IS_ENV_CDMX({ context })
      }

      if (!bEnvMontrealIsSet) {
        bMontreal = await IS_ENV_MONTREAL({ context })
      }

      if (!bEnvSncbIsSet) {
        bSncb = await IS_ENV_SNCB({ context })
      }
    }

    const envSupportedTzList = [
      { if: bCdmx, then: ['UTC', 'America/Mexico_City', 'Europe/Paris'] },
      { if: bMontreal, then: ['UTC', 'America/Montreal', 'Europe/Paris'] },
      { if: bSncb, then: ['UTC', 'Europe/Brussels', 'Europe/Paris'] }
    ]

    return envSupportedTzList.find(_case => _case.if)?.then ?? ['UTC', 'Europe/Paris']
  }

  async getSupportedTzOptions (): Promise<AppOption[]> {
    const supportedTzSet = new Set(await this.getSupportedTzList())
    return this.tzOptions.filter(o => supportedTzSet.has(o.value))
  }

  async setLocale (locale: string): Promise<void> {
    this.localeBS.next(locale)
    this.mLastLocaleChangeBS.next(NOW_APP())
  }

  async getLocale (_?: { locale?: string, lang?: string, override?: string }): Promise<string> {
    const _locale = _?.locale
    const bLocaleIsSet = IS_SET(_locale)

    let res: string = bLocaleIsSet ? _locale : ''

    if (!bLocaleIsSet) {
      const _lang = _?.lang
      const bLangIsSet = IS_SET(_lang)

      res = TO_STRING(bLangIsSet ? (await this.getMainLocaleFromLang(_))[_lang] : this.localeBS.value)
    }

    return IS_A_STRING_AND_NOT_EMPTY(res) ? res : LocaleService.defaultLocale
  }

  async getLang (_?: { locale?: any, lang?: any, override?: string }): Promise<string> {
    return TO_STRING(_?.lang ?? (await this.getLocale(_)).split('-')[0])
  }

  async getGeo (_?: { locale?: any, lang?: any, override?: string }): Promise<string> {
    return TO_STRING((_?.locale ?? (await this.getLocale(_))).split('-')[1])
  }

  async setTz (tz: string): Promise<void> {
    this.tzBS.next(tz)
    this.mLastTzChangeBS.next(NOW_APP())
  }

  getTz (_tz?: string): string {
    return _tz ?? this.tzBS.value
  }

  async localizeNumber (_: { value: any, options?: Intl.NumberFormatOptions | BigIntToLocaleStringOptions }): Promise<string> {
    let vD = ''

    const v = _.value

    if (IS_A_NUMBER(v) && IS_NUMERIC(v)) {
      vD = v.toLocaleString((await this.getLocale()), _.options as Intl.NumberFormatOptions)
    } else if (IS_INT(v)) {
      const bigInt = IS_A_BIGINT(v) ? v : TO_BIGINT(v)

      if (IS_SET(bigInt)) {
        try { vD = bigInt.toLocaleString((await this.getLocale()), _.options as BigIntToLocaleStringOptions) } catch { }
      }
    }

    return vD
  }

  async localizeNumberOrDash (_: { value: any, options?: Intl.NumberFormatOptions | BigIntToLocaleStringOptions }): Promise<string> {
    const localNumberDisplay = await this.localizeNumber(_)
    return IS_A_STRING_AND_NOT_EMPTY(localNumberDisplay) ? localNumberDisplay : '-'
  }

  localizeM (_: LocaleServiceLocalizeMParams): string {
    const { inTz, inFormat, outTz, outFormat } = _

    const epoch = TO_NUMBER(_.epoch)

    const input: any = _.input ?? (1000 * epoch)

    const timestampMs = TO_NUMBER(input)

    let m: DateTime = DateTime.invalid('undefined')

    if (input instanceof DateTime) {
      m = input
    } else if (IS_SET(inFormat)) {
      m = DateTime.fromFormat(TO_STRING(input), inFormat, IS_SET(inTz) ? { zone: inTz } : undefined)
    } else if (IS_A_STRING_AND_NOT_EMPTY(input)) {
      m = DateTime.fromISO(TO_STRING(input))
    } else if (IS_NUMERIC(timestampMs)) {
      m = DateTime.fromMillis(timestampMs)
    }

    let res = ''

    if (m.isValid) {
      if (IS_SET(outTz)) {
        m = m.setZone(outTz)
      }

      res = TO_STRING(IS_SET(outFormat) ? m.toFormat(outFormat) : m.toISO())
    }

    return res
  }

  localizeMOrDash (_: LocaleServiceLocalizeMParams): string {
    const localM = this.localizeM(_)
    return IS_A_STRING_AND_NOT_EMPTY(localM) ? localM : '-'
  }

  localizeYmd (_: LocaleServiceLocalizeMParams): string {
    return this.localizeM({ ..._, outFormat: LUXON_FORMAT_LOCAL_YMD })
  }

  localizeHm (_: LocaleServiceLocalizeMParams): string {
    return this.localizeM({ ..._, outFormat: LUXON_FORMAT_LOCAL_HI })
  }

  localizeHms (_: LocaleServiceLocalizeMParams): string {
    return this.localizeM({ ..._, outFormat: LUXON_FORMAT_LOCAL_HIS })
  }

  localizeYmdHm (_: LocaleServiceLocalizeMParams): string {
    return this.localizeM({ ..._, outFormat: LUXON_FORMAT_LOCAL_YMD_HI })
  }

  localizeYmdHms (_: LocaleServiceLocalizeMParams): string {
    return this.localizeM({ ..._, outFormat: LUXON_FORMAT_LOCAL_YMD_HIS })
  }

  localizeYmdHmsZ (_: LocaleServiceLocalizeMParams): string {
    return this.localizeM({ ..._, outFormat: LUXON_FORMAT_LOCAL_YMD_HIS_Z })
  }

  localizeYmdOrDash (_: LocaleServiceLocalizeMParams): string {
    return this.localizeMOrDash({ ..._, outFormat: LUXON_FORMAT_LOCAL_YMD })
  }

  localizeHmOrDash (_: LocaleServiceLocalizeMParams): string {
    return this.localizeMOrDash({ ..._, outFormat: LUXON_FORMAT_LOCAL_HI })
  }

  localizeHmsOrDash (_: LocaleServiceLocalizeMParams): string {
    return this.localizeMOrDash({ ..._, outFormat: LUXON_FORMAT_LOCAL_HIS })
  }

  localizeYmdHmOrDash (_: LocaleServiceLocalizeMParams): string {
    return this.localizeMOrDash({ ..._, outFormat: LUXON_FORMAT_LOCAL_YMD_HI })
  }

  localizeYmdHmsOrDash (_: LocaleServiceLocalizeMParams): string {
    return this.localizeMOrDash({ ..._, outFormat: LUXON_FORMAT_LOCAL_YMD_HIS })
  }

  localizeYmdHmsZOrDash (_: LocaleServiceLocalizeMParams): string {
    return this.localizeMOrDash({ ..._, outFormat: LUXON_FORMAT_LOCAL_YMD_HIS_Z })
  }

  async getLocaleName (_?: { locale?: any, lang?: any }): Promise<string> {
    const requested = this.getLocale(_)
    const lang = IS_A_STRING_AND_NOT_EMPTY(requested) ? requested : (await this.getLocale())
    const localeNameRecord = await this.getLocaleNameRecord()
    return TO_STRING(localeNameRecord[lang])
  }

  async getLangName (_?: { locale?: any, lang?: any }): Promise<string> {
    const requested = this.getLang(_)
    const lang = IS_A_STRING_AND_NOT_EMPTY(requested) ? requested : (await this.getLang())
    return TO_STRING(this.langName[lang])
  }

  async getGeoName (_?: { locale?: any, lang?: any }): Promise<string> {
    const requested = this.getGeo(_)
    const geo = IS_A_STRING_AND_NOT_EMPTY(requested) ? requested : (await this.getGeo())
    return TO_STRING(this.geoName[geo])
  }

  async toLC (s: string): Promise<string> {
    return s.toLocaleLowerCase(await this.getLocale())
  }

  async toUC (s: string): Promise<string> {
    return s.toLocaleUpperCase(await this.getLocale())
  }

  async toUCF (s: string): Promise<string> {
    const locale = await this.getLocale()

    const upperCaseHead = TO_STRING(s[0]).toLocaleUpperCase(locale)
    const lowerCaseTail = s.substring(1).toLocaleLowerCase(locale)

    return `${upperCaseHead}${lowerCaseTail}`
  }

  displayAuthFromValue (v: any): string {
    const res: string[] = []

    const bBigInt = IS_A_BIGINT(v)

    if (bBigInt || IS_NUMERIC(v)) {
      const n = bBigInt ? v : (TO_BIGINT(v) ?? 0n)

      if ((n & 1n) > 0) {
        res.push(this.valueToAuth[1])
      }

      if ((n & 2n) > 0) {
        res.push(this.valueToAuth[2])
      }

      if ((n & 4n) > 0) {
        res.push(this.valueToAuth[4])
      }

      if ((n & 8n) > 0) {
        res.push(this.valueToAuth[8])
      }
    }

    return res.join(' / ')
  }
}
