export const SynapseErrorList = [
  'INVALID_AUTH_HEADER',
  'INVALID_ISSUER',
  'INVALID_TOKEN',
  'EMPTY_TOKEN',
  'EXPIRED_TOKEN',
] as const

export type SynapseError = typeof SynapseErrorList[number]

export const IS_SYNAPSE_ERROR = (errorMessage: string): errorMessage is SynapseError => {
  return SynapseErrorList.includes(errorMessage as SynapseError)
}
