import { Component, OnInit } from "@angular/core"
import { MatButtonModule } from "@angular/material/button"
import { RouterModule } from "@angular/router"
import { AppDisplay } from "src/app/type/display"

@Component({
  imports: [RouterModule, MatButtonModule], 
  templateUrl: './synapse-error.component.html',
  styleUrls: ['./synapse-error.component.scss'],
  standalone: true,
})
export class AppUnexpectedSynapseErrorPage implements OnInit {
  display = new AppDisplay({
    cache: {
      unexpected_error_happened: '',
      try_to_connect_again_or_contact_your_system_administrator: '',
      connect_again: ''
    },
    entries: {
      unexpected_error_happened: {
        default: 'Unexpected error happened',
        fr: 'Une erreur inattendue s\'est produite',
        es: 'Se produjo un error inesperado',
        it: 'Si è verificato un errore imprevisto'
      },
      try_to_connect_again_or_contact_your_system_administrator: {
        default: 'Try to connect again or contact your system administrator',
        fr: 'Essayez de vous reconnecter ou contactez votre administrateur système',
        es: 'Intente conectarse nuevamente o contacte a su administrador de sistemas',
        it: 'Prova a riconnetterti o contatta il tuo amministratore di sistema'
      },
      connect_again: {
        default: 'Connect again',
        fr: 'Se connecter à nouveau',
        es: 'Volver a conectarse',
        it: 'Riconnettersi'
      }
    }
  })

  ngOnInit (): void {
    this.display.updateAll()
  }
}