import { inject, Injectable } from '@angular/core'
import { type DateTime, type Duration } from 'luxon'
import { BehaviorSubject } from 'rxjs'
import { type int } from 'src/app/type/int'
import { NOW_APP } from 'src/app/util/time.util'
import { IS_A_FUNCTION, IS_A_STRING, IS_A_STRING_AND_NOT_EMPTY, IS_NUMERIC, IS_ON, IS_SET } from '../../util/check.util'
import { TO_ABS, TO_BIGINT, TO_NUMBER } from '../../util/convert.util'
import { AuthService } from '../auth/auth.service'
import { HttpService } from '../request/http.service'
import { LocaleService } from './locale.service'

export interface DictionaryEntry {
  [b: string]: string
  default: string
}

export const IS_A_DICTIONARY_ENTRY = (x: any): x is DictionaryEntry => {
  return IS_A_STRING(x?.default)
}

export type DictionaryEntryFactory = (params?: any) => DictionaryEntry

export type DictionaryEntries = Record<string, DictionaryEntry | DictionaryEntryFactory>

export type DictionaryEntriesFactory = (params?: any) => DictionaryEntries

export interface GetLocalizedStringParams {
  fromEntry?: DictionaryEntry | DictionaryEntryFactory
  k?: string
  params?: any
  fromEntries?: DictionaryEntries | DictionaryEntriesFactory
}

@Injectable({ providedIn: 'root' })
export class DictionaryService {
  HttpService = inject(HttpService)
  AuthService = inject(AuthService)
  LocaleService = inject(LocaleService)

  mLastRefreshBS = new BehaviorSubject<DateTime | undefined>(undefined)

  lastUserHrJsonBS = new BehaviorSubject<string | undefined>(undefined)

  mLastRefreshLocaleBS = new BehaviorSubject<string | undefined>(undefined)

  mLastRefreshTzBS = new BehaviorSubject<string | undefined>(undefined)

  entries: any = {}

  // entries that cannot be simply sorted by line
  // long entries with the same key as entries will overwrite them (see this.initialize logic)
  longEntries = {
    alertResolved_on_dateTimeDisplay: (_?: { alResolved: any }) => {
      const alResolvedDisplay = IS_SET(_?.alResolved) ? this.LocaleService.localizeYmdHms({ input: _?.alResolved }) : ''

      const resolvedOnDisplayEntry = {
        default: `Resolved on ${alResolvedDisplay}`,
        fr: `Résolue le ${alResolvedDisplay}`,
        es: `Resuelto el ${alResolvedDisplay}`,
        it: `Risolto il ${alResolvedDisplay}`
      }

      return IS_A_STRING_AND_NOT_EMPTY(alResolvedDisplay) ? resolvedOnDisplayEntry : { default: this.get({ k: 'alertStill_active' }) }
    },

    durationDisplayParts: async (_: { duration: Duration, bAbsolute?: boolean }): Promise<string[]> => {
      const duration = _.duration
      const d = duration.shiftTo('years', 'months', 'days', 'hours', 'minutes', 'seconds').toObject()
      const bAbsolute = IS_ON(_.bAbsolute)
      const parts: Array<{ unit: string, value: number }> = [
        { unit: 'year', value: Math.trunc(TO_NUMBER(d.years)) },
        { unit: 'month', value: Math.trunc(TO_NUMBER(d.months)) },
        { unit: 'day', value: Math.trunc(TO_NUMBER(d.days)) },
        { unit: 'hour', value: Math.trunc(TO_NUMBER(d.hours)) },
        { unit: 'minute', value: Math.trunc(TO_NUMBER(d.minutes)) },
        { unit: 'second', value: Math.trunc(TO_NUMBER(d.seconds)) }
      ]
      const res: string[] = []
      for (const { unit, value } of parts) {
        if (IS_NUMERIC(value) && (value !== 0)) {
          const valueAbs = Math.abs(value)
          const bPlural = valueAbs > 1
          const v = bAbsolute ? valueAbs : value
          const valueDisplay = await this.LocaleService.localizeNumber({ value: v })
          const s = bPlural ? 's' : ''
          const displayUnitKey = `unit${await this.LocaleService.toUCF(unit)}${s}`
          const unitDisplay = await this.get({ k: displayUnitKey })
          res.push(`${valueDisplay} ${unitDisplay}`)
        }
      }
      return res
    },

    over_N_days: async (_: { over_N_days: number }): Promise<DictionaryEntry> => {
      const n = _.over_N_days
      const bMany = Math.abs(n) > 1
      const bPlural = isNaN(n) || bMany
      const s = bPlural ? 's' : ''
      const io = bPlural ? 'i' : 'o'
      const N = await this.LocaleService.localizeNumber({ value: n })
      return {
        default: bPlural ? `Over ${N} day${s}` : 'Over the day',
        fr: bPlural ? `Sur ${N} jour${s}` : 'Sur la journée',
        es: bPlural ? `Sobre ${N} día${s}` : 'Sobre el día',
        it: bPlural ? `Su ${N} giorn${io}` : 'Sulla giornata'
      }
    }
  }

  // set of { key: dictionary }, dictionary => set of { key: entry }, entry => set of { 'default'/locale/lang: string/template }
  extraDictionaries = {
    alertStatusUpdateProcess: async (_?: { n?: int }): Promise<Record<string, DictionaryEntry>> => {
      const n = TO_BIGINT(_?.n) ?? 0n
      const s = (TO_ABS(n) > 1n) ? 's' : ''
      const iE = IS_A_STRING_AND_NOT_EMPTY(s) ? 'i' : 'e'
      const iO = IS_A_STRING_AND_NOT_EMPTY(s) ? 'i' : 'o'
      const N = IS_A_STRING_AND_NOT_EMPTY(s) ? (await this.LocaleService.localizeNumber({ value: n })) : ''
      return {

        titleSend: {
          default: IS_A_STRING_AND_NOT_EMPTY(s) ? `Notify about the ${N} selected alerts?` : 'Notify about this alert?',
          fr: IS_A_STRING_AND_NOT_EMPTY(s) ? `Notifier à propos des ${N} alertes sélectionnées ?` : 'Notifier de cette alerte ?',
          es: IS_A_STRING_AND_NOT_EMPTY(s) ? `Notificar sobre las ${N} alertas seleccionadas ?` : 'Notificar sobre esta alerta ?',
          it: IS_A_STRING_AND_NOT_EMPTY(s) ? `Notifica degli ${N} allarmi selezionati ?` : 'Notifica di questo allarme ?'
        },
        titleReset: {
          default: IS_A_STRING_AND_NOT_EMPTY(s) ? `Reset the ${N} selected alerts?` : 'Reset this alert?',
          fr: IS_A_STRING_AND_NOT_EMPTY(s) ? `Remettre à zéro les ${N} alertes sélectionnées ?` : 'Remettre à zéro cette alerte ?',
          es: IS_A_STRING_AND_NOT_EMPTY(s) ? `Restablecer las ${N} alertas seleccionadas ?` : 'Restablecer esta alerta ?',
          it: IS_A_STRING_AND_NOT_EMPTY(s) ? `Ripristina gli ${N} allarmi selezionati ?` : 'Ripristina questo allarme ?'
        },
        titleInProgress: {
          default: IS_A_STRING_AND_NOT_EMPTY(s) ? `Mark the ${N} selected alerts in progress?` : 'Set this alert in progress?',
          fr: IS_A_STRING_AND_NOT_EMPTY(s) ? `Les ${N} alertes sélectionnées sont en cours ?` : 'Cette alerte est en cours ?',
          es: IS_A_STRING_AND_NOT_EMPTY(s) ? `Las ${N} alertas seleccionadas están en curso ?` : 'Esta alerta está en curso ?',
          it: IS_A_STRING_AND_NOT_EMPTY(s) ? `Gli ${N} allarmi selezionati sono in corso ?` : 'Questo allarme è stato supportato ?'
        },
        titleResolve: {
          default: IS_A_STRING_AND_NOT_EMPTY(s) ? `Mark the ${N} selected alerts as resolved?` : 'Mark this alert as resolved?',
          fr: IS_A_STRING_AND_NOT_EMPTY(s) ? `Marquer les ${N} alertes sélectionnées comme résolues ?` : 'Marquer cette alerte comme résolue ?',
          es: IS_A_STRING_AND_NOT_EMPTY(s) ? `Marcar las ${N} alertas seleccionadas como resueltas ?` : 'Marcar esta alerta como resuelta ?',
          it: IS_A_STRING_AND_NOT_EMPTY(s) ? `Marchia risoluti gli ${N} allarmi selezionati ?` : 'Marchia risoluto questo allarme ?'
        },
        titleArchive: {
          default: IS_A_STRING_AND_NOT_EMPTY(s) ? `Archive these ${N} alerts?` : 'Archive this alert?',
          fr: IS_A_STRING_AND_NOT_EMPTY(s) ? `Archiver les ${N} alertes sélectionnées ?` : 'Archiver cette alerte ?',
          it: IS_A_STRING_AND_NOT_EMPTY(s) ? `Archivia gli ${N} allarmi selezionati ?` : 'Archivia questo allarme ?'
        },
        titleArchiveAll: { default: 'Archive all alerts?', fr: 'Archiver toutes les alertes ?', it: 'Archivia tutti gli allarmi ?' },

        textSend: {
          default: (
            IS_A_STRING_AND_NOT_EMPTY(s)
              ? `The contacts bellow will be notified about these ${N} selected alerts. `
              : 'The contacts bellow will be notified about this alert. '
          ),
          fr: (
            IS_A_STRING_AND_NOT_EMPTY(s)
              ? `Les contacts ci-dessous vont être notifiés des ${N} alertes sélectionnées. `
              : 'Les contacts ci-dessous vont être notifiés de cette alerte. '
          ),
          it: (
            IS_A_STRING_AND_NOT_EMPTY(s)
              ? `I contatti sottostanti verranno avvisati degli ${N} allarmi selezionati. `
              : 'I contatti sottostanti verranno avvisati di questo allarme. '
          )
        },
        textReset: {
          default: IS_A_STRING_AND_NOT_EMPTY(s) ? `The ${N} alerts will be reactivated. ` : 'The alert will be reactivated. ',
          fr: IS_A_STRING_AND_NOT_EMPTY(s) ? `Les ${N} alertes vont être réactivées. ` : 'L\'alerte va être réactivée. ',
          it: IS_A_STRING_AND_NOT_EMPTY(s) ? `Gli ${N} allarmi verranno riattivati. ` : 'L\'allarme verra riattivato. '
        },
        textInProgress: {
          default: (
            IS_A_STRING_AND_NOT_EMPTY(s)
              ? `The ${N} alerts will be marked as "in progress". `
              : 'The alert will be marked as "in progress". '
          ),
          fr: (
            IS_A_STRING_AND_NOT_EMPTY(s)
              ? `Les ${N} alertes vont être marquées comme "en cours". `
              : 'L\'alerte va être marquées comme "en cours". '
          ),
          it: (
            IS_A_STRING_AND_NOT_EMPTY(s)
              ? `Gli ${N} allarmi saranno contrassegnati come "in corso". `
              : 'L\'allarme sarà contrassegnato come "in corso". '
          )
        },
        textResolve: {
          default: 'Some alerts cannot be automatically resolved. Manual status update required. ',
          fr: 'Certaines alertes ne peuvent pas être marquées comme résolues automatiquement. Leur mise à jour manuelle est requise. ',
          it: 'Alcuni allarmi non possono venire marchiati risoluti automaticamente. È necessario l\'aggiornamento manuale del loro stato. '
        },
        textArchive: {
          default: 'Old or no longer relevant alerts can be archived to easily filter them out of the next search results. ',
          fr: `
            Les alertes qui ne sont plus pertinentes ou trop anciennes peuvent être archivées
            pour être écartées facilement des prochains résultats de recherche.
          `,
          it: `
            È possibile archiviare allarmi vecchi o non più pertinenti per filtrarli facilmente
            dai risultati di ricerca successivi.
          `
        },
        textArchiveAll: {
          default: 'All alerts currently in the database will be archived. ',
          fr: 'Toutes les alertes actuellement en base de donnée vont être archivées. ',
          it: 'Tutti gli allarmi attualmente presenti nel database verranno archiviati. '
        },

        doneSent: { default: 'Alert notification sent', fr: 'Notification d\'alerte envoyée', it: 'Notifica di allarme inviata' },
        doneReset: { default: `Alert${s} reset`, fr: `Alerte${s} réactivée${s}`, it: `Allarm${iE} ripristinat${iO}` },
        doneInProgress: { default: `Alert${s} in progress`, fr: `Alerte${s} en cours`, it: `Allarm${iE} in corso` },
        doneResolve: { default: `Alert${s} resolved`, fr: `Alerte${s} résolue${s}`, it: `Allarm${iE} risolut${iO}` },
        doneArchived: { default: `Alert${s} archived`, fr: `Alerte${s} archivée${s}`, it: `Allarm${iE} archiviat${iO}` },
        doneArchivedAll: {
          default: 'All alerts have been archived',
          fr: 'Toutes les alertes ont été archivées',
          it: 'Tutti gli allarmi sono stati archiviati'
        }

      }
    }

  }

  async refresh (_?: { locale?: string, tz?: string }): Promise<void> {
    const locale = _?.locale ?? (await this.LocaleService.getLocale())
    const tz = _?.tz ?? this.LocaleService.getTz()

    const options = this.AuthService.getHttpOptionsWithBearerTokenAndContentTypeJson()

    if (!IS_SET(options.params)) {
      options.params = {}
    }

    options.params.locale = locale
    options.params.tz = tz

    const { exception, result } = await this.HttpService.get({ path: '/dictionary_entries_translated', options })

    const entries = (!IS_SET(exception) && IS_SET(result)) ? result : {}

    // merge long entries with entries here (overwrite identical keys)
    this.entries = { ...entries, ...this.longEntries }

    const currentUser = this.AuthService.currentUserDataBS.value
    const currentUserHrJson = IS_SET(currentUser) ? JSON.stringify(currentUser, null, 2) : undefined

    // extra dictionaries remain independent
    this.mLastRefreshBS.next(NOW_APP())
    this.lastUserHrJsonBS.next(currentUserHrJson)
    this.mLastRefreshLocaleBS.next(locale)
    this.mLastRefreshTzBS.next(tz)
  }

  async get (_: { fromEntry?: any, k?: string, params?: any, fromEntries?: any }): Promise<string> {
    let res = ''

    const bK = IS_SET(_?.k)
    const bParams = IS_SET(_?.params)
    const bFromEntry = IS_SET(_?.fromEntry)
    const bFromEntries = IS_SET(_?.fromEntries)

    let entry: any

    if (bFromEntry) {
      entry = IS_A_FUNCTION(_.fromEntry) ? (bParams ? _.fromEntry(_.params) : _.fromEntry()) : _.fromEntry
    } else if (bK) {
      if (bFromEntries) {
        const entries = IS_A_FUNCTION(_.fromEntries) ? (bParams ? _.fromEntries(_.params) : _.fromEntries()) : _.fromEntries
        entry = entries[_.k ?? '!!!']
      } else {
        entry = this.entries[_.k ?? '!!!']
      }
    }

    if (IS_A_STRING(entry)) {
      res = entry
    } else if (IS_SET(entry)) {
      if (IS_A_FUNCTION(entry)) {
        entry = IS_SET(_.params) ? entry(_.params) : entry()
      }

      const locale = await this.LocaleService.getLocale()
      const lang = await this.LocaleService.getLang()

      const translator: any = entry[locale] ?? entry[lang] ?? entry.default

      res = IS_A_FUNCTION(translator) ? (bParams ? translator(_.params) : translator()) : translator
    }

    return res
  }

  async getLC (_: { fromEntry?: any, k?: string, params?: any, fromEntries?: any }): Promise<string> {
    const res = await this.LocaleService.toLC(await this.get(_))
    return res
  }

  async getUC (_: { fromEntry?: any, k?: string, params?: any, fromEntries?: any }): Promise<string> {
    const res = await this.LocaleService.toUC(await this.get(_))
    return res
  }

  async getUCF (_: { fromEntry?: any, k?: string, params?: any, fromEntries?: any }): Promise<string> {
    const res = await this.LocaleService.toUCF(await this.get(_))
    return res
  }
}
