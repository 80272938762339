<div
  mat-dialog-title
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragBoundary=".cdk-overlay-container"
  cdkDragHandle
  class="bg_{{ themeType }} grabbable"
>
  <h5>{{ display.cache.title }}</h5>

  <button
    mat-icon-button
    mat-dialog-close
  >
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <p *ngIf="display.cache.message">{{ display.cache.message }}</p>
</div>

<div
  mat-dialog-actions
  class="border_shadow_top_inset"
>
  <div class="mat_button_density_0_container">
    <button
      mat-raised-button
      mat-dialog-close
    >{{ display.cache.cancel }}</button>
  </div>

  <div class="mat_button_density_0_container">
    <button
      (click)="confirm()"
      mat-raised-button
      [color]="themeType"
    >{{ display.cache.confirm }}</button>
  </div>
</div>
