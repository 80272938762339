import { RsstemplateEnvironment } from './environment.rsstemplate'

class RsstestEnvironment extends RsstemplateEnvironment {
  override production = '0' as const
  override serverType = 'TEST'
  // override context
  // override dbSsv
  override apiEndpoint = 'https://dev.rss.samsup.tik.fr/rssapi'
  override serverOsFamilly: 'linux' | 'windows' | 'macos' = 'linux' as const
  // override timezone
  // override lang
  // override geo
  // override locale
  // override csnDisplay

  override synapseClientId = '0oa7qv9rlkYDxlWds0x7'
  override hostname = 'https://dev.rss.samsup.tik.fr/admin'
}

export const ENV_RSSTEST = new RsstestEnvironment()

export const environment = ENV_RSSTEST
