import { InjectionToken, type Injector } from '@angular/core'
import { type OktaAuth, type OktaAuthOptions, type TokenParams } from '@okta/okta-auth-js'

export type AuthRequiredFunction = (oktaAuth: OktaAuth, injector?: Injector, options?: TokenParams) => Promise<void>

export interface TestingObject {
  disableHttpsCheck: boolean
}

export interface OktaConfig {
  oktaAuth: OktaAuth
  onAuthRequired: AuthRequiredFunction
  onAuthResume: AuthRequiredFunction
  testing?: TestingObject
}

export type OktaAuthOptionsExtended = OktaAuthOptions & { testing?: { disableHttpsCheck?: boolean } }

export const OKTA_CONFIG = new InjectionToken<OktaConfig>('okta.config.angular')

export const OKTA_AUTH = new InjectionToken<OktaAuth>('okta.auth')

export const OKTA_AUTH_OPTIONS = new InjectionToken<OktaAuthOptionsExtended>('okta.auth.options')
