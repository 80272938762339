<div
  mat-dialog-title
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragBoundary=".cdk-overlay-container"
  cdkDragHandle
  class="bg_{{ themeType }} grabbable"
>
  <h5>{{ title }}</h5>
  <button
    mat-icon-button
    mat-dialog-close
    [matTooltip]="display.cache.close"
  >
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <p *ngIf="message">{{ message }}</p>
  <pre>{{ text }}</pre>
</div>

<div
  mat-dialog-actions
  class="border_shadow_top_inset"
>
  <div class="mat_button_density_0_container">
    <button
      mat-raised-button
      mat-dialog-close
    >{{ display.cache.close }}</button>
  </div>
</div>
