import { Injectable } from '@angular/core'
import { type MatSnackBar, type MatSnackBarHorizontalPosition, type MatSnackBarRef, type MatSnackBarVerticalPosition } from '@angular/material/snack-bar'
import { THEME_STYLES, type THEME_TYPE } from 'src/app/type/theme'
import { IS_NUMERIC } from 'src/app/util/check.util'
import { TO_NUMBER } from 'src/app/util/convert.util'
import { SnackbarComponent } from './snackbar.component'

const snackbarPanelClassList: any = {}

for (const s of THEME_STYLES) {
  snackbarPanelClassList[s] = [`snackbar_${s}`]
}

export const SNACKBAR_PANEL_CLASS_LIST: Record<THEME_TYPE, string[]> = snackbarPanelClassList

const snackbarCloseClassList: any = {}

for (const s of THEME_STYLES) {
  snackbarCloseClassList[s] = [`bg_${s}`]
}

export const SNACKBAR_CLOSE_CLASS_LIST: Record<THEME_TYPE, string[]> = snackbarCloseClassList

export interface SNACKBAR_SERVICE_SHOW_PARAMS {
  message: any
  type?: THEME_TYPE
  duration?: number
  closeWith?: string
  closeType?: THEME_TYPE
}

@Injectable({ providedIn: 'root' })
export class SnackbarService {
  matSnackBar!: MatSnackBar

  emptyString = ''

  closeDisplay = ''

  /**
   * by default: show info message
   */
  show (_: SNACKBAR_SERVICE_SHOW_PARAMS): MatSnackBarRef<SnackbarComponent> {
    const { message, type, closeWith, closeType } = _
    let duration = TO_NUMBER(_.duration)

    const panelClass = SNACKBAR_PANEL_CLASS_LIST[type ?? 'info']
    const closeString = closeWith ?? ((Infinity === duration) ? this.closeDisplay : this.emptyString)
    const closeClass = SNACKBAR_CLOSE_CLASS_LIST[closeType ?? type ?? 'info']

    duration = IS_NUMERIC(duration) ? duration : (3 * 1000)

    const data = { panelClass, message, closeString, closeClass, duration }

    const horizontalPosition: MatSnackBarHorizontalPosition = 'center'
    const verticalPosition: MatSnackBarVerticalPosition = 'bottom'

    return this.matSnackBar.openFromComponent(SnackbarComponent, { data, duration, panelClass, horizontalPosition, verticalPosition })
  }

  showDanger (message = 'Danger'): MatSnackBarRef<SnackbarComponent> {
    return this.show({ message, type: 'danger', duration: 30 * 1000, closeWith: this.closeDisplay })
  }

  showWarn (message = 'Warn'): MatSnackBarRef<SnackbarComponent> {
    return this.show({ message, type: 'warn', duration: 10 * 1000, closeWith: this.closeDisplay })
  }

  showInfo (message = 'Info'): MatSnackBarRef<SnackbarComponent> {
    return this.show({ message, type: 'info', duration: 3 * 1000, closeWith: this.closeDisplay })
  }

  showSuccess (message = 'Success'): MatSnackBarRef<SnackbarComponent> {
    return this.show({ message, type: 'success', duration: 8 * 1000, closeWith: this.closeDisplay })
  }

  showDefaultServerError (): MatSnackBarRef<SnackbarComponent> {
    return this.showDanger('Server error, please try again later')
  }
}
