export const ROLE_DISPLAY_KEY_LIST = ['admin__terminals', 'admin__sam', 'admin_', 'read_only'] as const

export const RESTRICTED_TO_SUPER_ADMIN_SET = new Set(['accounts'])

export type SynapseInfo = {
  issuer: string
  authorization_endpoint: string
  token_endpoint: string
  end_session_endpoint: string
  revocation_endpoint: string
  introspection_endpoint: string
}
