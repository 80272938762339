import { inject } from '@angular/core'
import { DictionaryService, type DictionaryEntry } from '../core/context/dictionary.service'
import { IS_A_FUNCTION } from '../util/check.util'

export interface DisplayUpdate extends Record<string, (_?: any) => Promise<void>> {
  all: (row?: any) => Promise<void>
}

export interface Display extends Record<string, any> {
  dictionary?: Record<string, any>
  entries?: Record<string, DictionaryEntry>
  cache: Record<string, string>
  update: DisplayUpdate
}

export class AppDisplay {
  DictionaryService = inject(DictionaryService)

  cache: Record<string, string> = {}

  entries?: Record<string, DictionaryEntry> = {}

  dictionary?: Record<string, any> = {}

  constructor (display: Partial<AppDisplay>) {
    Object.assign(this, display)
  }

  async updateAll (): Promise<void> {
    for (const k of Object.keys(this.cache)) {
      this.cache[k] = await this.DictionaryService.get({ k })
    }

    for (const [k, fromEntry] of Object.entries(this.entries ?? {})) {
      this.cache[k] = await this.DictionaryService.get({ fromEntry })
    }

    for (const entries of Object.values(this.dictionary ?? {})) {
      const fromEntries = IS_A_FUNCTION(entries) ? (await entries()) : entries

      for (const [k, fromEntry] of Object.entries(fromEntries)) {
        this.cache[k] = await this.DictionaryService.get({ fromEntry })
      }
    }
  }
}
