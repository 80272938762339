import { HttpClient } from '@angular/common/http'
import { inject, Injectable, Injector } from '@angular/core'
import { Router } from '@angular/router'
import { jwtDecode } from 'jwt-decode'
import { BehaviorSubject, lastValueFrom, of, Subject } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { IS_A_STRING_AND_NOT_EMPTY, IS_AN_ARRAY, IS_OFF, IS_ON, IS_SET } from 'src/app/util/check.util'
import { TO_ANY_ARRAY, TO_NUMBER, TO_STRING } from 'src/app/util/convert.util'
import { GET_ENV_API_ENDPOINT, IS_ENV_RSS } from 'src/app/util/env.util'
import { LOCAL_STORAGE_GET_EXPIRES_AT_KEY, LOCAL_STORAGE_GET_VALUE_AT_KEY, LOCAL_STORAGE_REMOVE_AT_KEY, LOCAL_STORAGE_SET_OBJECT_AT_KEY } from 'src/app/util/local-storage.util'
import { MILLISECONDS_IN_ONE_DAY, NOW_MS } from 'src/app/util/time.util'
import { IS_A_DICTIONARY_ENTRY, type DictionaryEntry } from '../context/dictionary.service'
import { OKTA_AUTH } from '../okta/okta.config'
import { IS_SYNAPSE_ERROR } from '../okta/okta.utils'
import { AUTH_JWT_KEY } from './auth-config'

@Injectable({ providedIn: 'root' })
export class AuthService {
  HttpClient = inject(HttpClient)
  Router = inject(Router)
  Injector = inject(Injector)

  b15Days = false

  expiresMs = -1

  expires = (new Date(this.expiresMs)).toISOString()

  requestedUrl = ''

  loginErrorBS = new BehaviorSubject<string | DictionaryEntry | undefined>(undefined)

  currentUserDataBS = new BehaviorSubject<any>(undefined)

  // cookies = new Cookies()

  // storedValueMetaDataSeparator = '§'

  bAllowLocalLogin = false

  bLoggedIn = (
    IS_A_STRING_AND_NOT_EMPTY(this.getStoredJwt()) ||
    IS_A_STRING_AND_NOT_EMPTY(this.getStoredAuthToken()) ||
    IS_A_STRING_AND_NOT_EMPTY(this.getStoredRefreshToken())
  )

  newLoggedInStateS = new Subject<boolean>()

  newAuthenticationStateBS = new BehaviorSubject<boolean>(this.bLoggedIn)

  getStoredJwt (): string {
    return TO_STRING(LOCAL_STORAGE_GET_VALUE_AT_KEY(AUTH_JWT_KEY))
  }

  getStoredAuthToken (): string {
    return TO_STRING(LOCAL_STORAGE_GET_VALUE_AT_KEY(AUTH_JWT_KEY))
  }

  getStoredRefreshToken (): string {
    return TO_STRING(LOCAL_STORAGE_GET_VALUE_AT_KEY(AUTH_JWT_KEY))
  }

  getHttpOptionsWithContentTypeJson (): any {
    const o: any = { headers: { 'Content-Type': 'application/json' } }
    return o
  }

  getHttpOptionsWithBearerToken (): any {
    const jwt = this.getStoredAuthToken()
    const o: any = { headers: { Authorization: `Bearer ${jwt}` } }
    return o
  }

  getHttpOptionsWithBearerTokenAndContentTypeJson (): any {
    const o: any = this.getHttpOptionsWithBearerToken()
    o.headers['Content-Type'] = 'application/json'
    return o
  }

  getHttpOptionsWithBearerTokenAndResponseTypeBlob (): any {
    const o: any = this.getHttpOptionsWithBearerToken()
    o.responseType = 'blob'
    return o
  }

  async refreshCurrentUser (): Promise<any> {
    const apiEndpoint = await GET_ENV_API_ENDPOINT()
    const _url = `${apiEndpoint}/profile`

    const getRes = async () => {
      const _options = this.getHttpOptionsWithBearerToken()
      const _res = (await lastValueFrom(this.HttpClient.get(_url, _options).pipe(catchError(err => of(err))))) ?? {}
      return { error: _res.error, userData: IS_SET(_res.error) ? undefined : _res }
    }

    let { userData, error } = await getRes()

    const isEnvRSS = await IS_ENV_RSS()

    if (
      isEnvRSS &&
      (error?.statusCode === 403) &&
      (error?.message === 'SYNAPSE_ACCOUNT_UNAUTHORIZED')
    ) {
      await this.Router.navigate(['unauthorized'])
    }

    if (isEnvRSS && IS_SYNAPSE_ERROR(error?.message)) {
      console.error('refreshCurrentUser > Synapse error:', error)

      let bGoToSynapseError = true

      const oktaAuth = await this.Injector.get(OKTA_AUTH)
      console.log('refreshCurrentUser > oktaAuth', oktaAuth)
      const token = await oktaAuth.getOrRenewAccessToken()

      if (IS_SET(token)) {
        await this.updateToken(token)

        const res = await getRes()

        bGoToSynapseError = false
        userData = res.userData
        this.bLoggedIn = true
      } else {
        this.bLoggedIn = false
      }

      if (bGoToSynapseError) {
        console.error('Synapse error:', error?.message, error?.statusCode)
        await this.Router.navigate(['synapse-error'])
      }
    }

    this.currentUserDataBS.next(userData)

    return userData
  }

  isAllowed (_: {
    role: string
    roleSet?: Set<string>
    roleList?: string[]
  }): boolean {
    const roleSet = (
      _.roleSet ??
      new Set(IS_AN_ARRAY(_.roleList) ? _.roleList : TO_ANY_ARRAY(this.currentUserDataBS.value?.roleList).map(TO_STRING))
    )
    return roleSet.has('IS_SUPER_ADMIN') || roleSet.has(_.role)
  }

  async logOut (): Promise<void> {
    // LOCAL_STORAGE_REMOVE_AT_KEY(AUTH_ROLE_LIST_KEY)
    LOCAL_STORAGE_REMOVE_AT_KEY(AUTH_JWT_KEY)
    this.newLoggedInStateS.next(false)
    this.newAuthenticationStateBS.next(false)
    this.bLoggedIn = false

    // if (await IS_ENV_RSS()) {
    //   console.log('synapseLogout')
    //   try {
    //     await lastValueFrom(this.SynapseAuthService.logout())
    //   } catch (error) {
    //     console.error('SynapseAuthService.logout() error:', error)
    //   }
    // }
  }

  async logIn (_: { account: string, password: string, b15Days?: boolean | number }): Promise<boolean> {
    const b15Days = IS_ON(_.b15Days)
    this.b15Days = b15Days

    const expiresMs = NOW_MS() + ((b15Days ? 15 : 1) * TO_NUMBER(MILLISECONDS_IN_ONE_DAY))
    this.expiresMs = expiresMs

    const expires = (new Date(expiresMs)).toUTCString()
    this.expires = expires

    const apiEndpoint = await GET_ENV_API_ENDPOINT()

    const _url = `${apiEndpoint}/public_auth`

    const _body = {
      grantType: 'password',
      clientId: '',
      clientSecret: '',
      username: _.account,
      password: _.password,
      expiresMs,
    }

    const _options = this.getHttpOptionsWithContentTypeJson()

    const res: any = (await lastValueFrom(this.HttpClient.post(_url, _body, _options).pipe(catchError(err => of(err))))) ?? {}

    const bLogInOK = IS_A_STRING_AND_NOT_EMPTY(res.access_token)

    if (bLogInOK) {
      LOCAL_STORAGE_SET_OBJECT_AT_KEY({ key: AUTH_JWT_KEY, value: TO_STRING(res.access_token), attributes: { expires } })

      this.loginErrorBS.next(undefined)
      await this.refreshCurrentUser()
      this.bLoggedIn = true
      this.newAuthenticationStateBS.next(true)
      this.newLoggedInStateS.next(true)
    } else {
      const errorDescription: any = res.error_description

      if (IS_A_STRING_AND_NOT_EMPTY(errorDescription) || IS_A_DICTIONARY_ENTRY(errorDescription)) {
        this.loginErrorBS.next(errorDescription)
      } else if (TO_STRING(res.error?.code) === 'ECONNREFUSED') {
        this.loginErrorBS.next({
          default: 'The database is not available',
          fr: 'La base de données n\'est pas disponible',
          es: 'La base de datos no está disponible',
          it: 'Il database non è disponibile',
        })
      } else {
        this.loginErrorBS.next({
          default: 'The authentication service is not available',
          fr: 'Le service d\'authentification n\'est pas disponible',
          es: 'El servicio de autenticación no está disponible',
          it: 'Il servizio di autenticazione non è disponibile',
        })
      }
    }

    return bLogInOK
  }

  async updateToken (token: string): Promise<void> {
    LOCAL_STORAGE_SET_OBJECT_AT_KEY({ key: AUTH_JWT_KEY, value: TO_STRING(token) })
    this.loginErrorBS.next(undefined)
    await this.refreshCurrentUser()
    this.bLoggedIn = true
    this.newAuthenticationStateBS.next(true)
    this.newLoggedInStateS.next(true)
  }

  async refreshToken (): Promise<boolean> {
    const refreshToken = this.getStoredRefreshToken()

    // ? no refresh token ? log out
    if (IS_OFF(refreshToken)) {
      await this.logOut()
    }

    // ? decode the refresh jwt token to retrieve the expiration date
    const decoded: any = jwtDecode(refreshToken)

    const expiresMs = TO_NUMBER(decoded?.exp ?? -1) * 1000
    this.expiresMs = expiresMs

    // ? the refresh token is expired ? log out
    if (expiresMs < NOW_MS()) {
      await this.logOut()
    }

    // ? request a new token by refresh_token
    const apiEndpoint = await GET_ENV_API_ENDPOINT()

    const _url = `${apiEndpoint}/public_auth`

    const _body = {
      grantType: 'refresh_token',
      clientId: '',
      clientSecret: '',
      refreshToken,
      expiresMs,
    }

    const _options = this.getHttpOptionsWithContentTypeJson()

    const res: any = (await lastValueFrom(this.HttpClient.post(_url, _body, _options).pipe(catchError(err => of(err))))) ?? {}

    const bRefreshTokenOK = IS_A_STRING_AND_NOT_EMPTY(res.access_token)

    // ? refresh token is empty ? log out
    if (!bRefreshTokenOK) {
      await this.logOut()
    }

    // ? refresh token is not empty ? update the cookie
    let expires = LOCAL_STORAGE_GET_EXPIRES_AT_KEY(AUTH_JWT_KEY)

    if (!IS_A_STRING_AND_NOT_EMPTY(expires)) {
      expires = new Date(expiresMs).toUTCString()
      this.expires = expires
    }

    LOCAL_STORAGE_SET_OBJECT_AT_KEY({ key: AUTH_JWT_KEY, value: TO_STRING(res.access_token), attributes: { expires } })

    if (!this.bLoggedIn) {
      this.bLoggedIn = true
      await this.refreshCurrentUser()
      this.newAuthenticationStateBS.next(true)
    }

    return bRefreshTokenOK
  }

  async isStillLoggedIn (): Promise<boolean> {
    let bLoggedIn = this.bLoggedIn

    if (bLoggedIn) {
      const currentUser = await this.refreshCurrentUser()

      bLoggedIn = IS_SET(currentUser)

      if (!bLoggedIn) {
        await this.logOut()
      }
    }

    return bLoggedIn
  }
}
