import { Component, inject, type OnDestroy, type OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AppDisplay } from 'src/app/type/display'
import { type THEME_TYPE } from 'src/app/type/theme'
import { IS_SET } from 'src/app/util/check.util'
import { DictionaryService } from '../context/dictionary.service'
import { LocaleService } from '../context/locale.service'

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit, OnDestroy {
  MAT_DIALOG_DATA = inject(MAT_DIALOG_DATA)
  MatDialogRef = inject<MatDialogRef<ConfirmDialogComponent>>(MatDialogRef<ConfirmDialogComponent>)
  LocaleService = inject(LocaleService)
  DictionaryService = inject(DictionaryService)

  bAlive = false

  themeType: THEME_TYPE = 'primary'

  display = new AppDisplay({
    cache: {
      title: '',
      message: '',
      cancel: '',
      confirm: ''
    }
  })

  async ngOnInit (): Promise<void> {
    this.bAlive = true

    await this.display.updateAll()

    const title = this.MAT_DIALOG_DATA.title
    const message = this.MAT_DIALOG_DATA.message

    this.display.cache.title = title
    this.display.cache.message = message

    const themeType = this.MAT_DIALOG_DATA.themeType

    if (IS_SET(themeType)) {
      this.themeType = themeType
    }

    const cancelText = this.MAT_DIALOG_DATA.cancelText

    if (IS_SET(cancelText)) {
      this.display.cache.cancel = cancelText
    }

    const confirmText = this.MAT_DIALOG_DATA.confirmText

    if (IS_SET(confirmText)) {
      this.display.cache.confirm = confirmText
    }
  }

  confirm (): void {
    this.MatDialogRef.close({ bConfirmed: true })
  }

  ngOnDestroy (): void {
    this.bAlive = false
  }
}
